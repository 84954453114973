/***
Calcule une valeur en rem
en fonction de la taille de typo de base du document en pixels
et de la taille en pixels fournie
***/
@function pxrem($px)
{
  $basePx: 16;

  $rem: $px / $basePx * 1rem;

  @return $rem;
}
