

/* appear - on page load */
.fade-appear {
  opacity: 0;
  z-index: 1;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

/* enter */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

/* enter */
.fade-enter-done {
  opacity: 1;
  z-index: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms linear;
}

/* exit */
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms linear;
}
.fade-exit-done {
  opacity: 0;
}
