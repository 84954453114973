#why-banner {
  position: relative;
  display: flex;
  align-items: center;
  height: 200px;
  // padding-top: 50px;
  background: #f6f7f9 url('/images/why/enfant-pere-2.jpg') no-repeat 50% 50%;
  background-size: cover;

  h1 {
    width: 200px;
    margin-left: 5px;
    font-size: pxrem(20);
    line-height: pxrem(22);
    letter-spacing: pxrem(.4);
    font-weight: 300;
    text-align:left;
    color: $txBlue;

    span {
      display: block;
      width: 130px;
      margin-bottom: 15px;
    }
  }
}

.why-section {
  margin-bottom: 70px;

  h2 {
    @include h3-style;
    margin: 30px 0;
    // @include font-size(1.4375rem);
    // text-transform: uppercase;
    // color: $txOrange;
  }

  h3 {
    @include h4-style;
    // @include font-size(1.0625rem);
    // color: $txBlue;
  }
}

#why-section-1 {
  margin: 20px 0 40px;

  .chapeau {
    margin-bottom: 0;

    &::after {
      content: '';
      display: block;
      width: 230px;
      margin: 40px auto 0;
      border-bottom: 1px solid $txOrange;
    }
  }
}

#why-section-2 {

  p {
    margin-bottom: 5px;
  }

  h3 {
    text-align: left;
    margin-bottom: 30px;

    &.last {
      margin-bottom: 0;
    }
  }
}

#why-section-3 {
  background-color: #f5f5f5;

  h2{
    text-align:left;
  }

  > .container {
    background-color: #f5f5f5;

  }

  .vulnerable-illustration {
    text-align: center;

    img {
      max-width: 100%;
    }

    .note {
      margin: 15px 0 30px;
      font-size: pxrem(11);
      text-align: center;
    }
  }

  h3 {
    margin-bottom: 22px;
    text-align: left;
  }


}

#why-section-4 {
  .chapeau {
    margin-bottom: 0;
    font-weight: 400;
    color: #707070;
  }
}

#why-section-5 {
  text-align: center;
  background-color: #f5f5f5;

  > .container {
    background-color: #f5f5f5;
  }
}

#why-section-6 {
  margin-bottom: 40px;

  h3 {
    text-align: left;
  }

  p {
    margin-bottom: 40px;
   }

  ul {
    margin-left: 15px;
    padding-left: 0;
    list-style: outside;
  }

  ul:last-child{
    margin-bottom:0;
  }
}

#why-section-7 {
  padding-bottom: 160px;

  h2 {
    margin-top: 0;
  }

  .cadre {
    overflow: visible;
    padding: 20px;
    border: 1px solid $txBlue;
  }

  .illustration {
    position: relative;
    top: 180px;
    margin-top: -180px;
    text-align: center;

    img {
      width: 260px;
    }
  }
}

#why-section-8 {
  margin-bottom: 120px;

  h3 {
    text-align: left;
  }

  .encart {
    padding: 20px;
    background-color: #f5f5f5;

    h3 {
      text-align: center;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .why-page-section-7 {
    margin-bottom: 180px;
  }

  #why-section-3 h2{
      text-align:center;
    }
}

@include media-breakpoint-down(xs) {
  #why-section-6 p{
      padding-bottom: 40px;
    }
  }
