/*
* Common
*/
@import url('https://fonts.googleapis.com/css?family=Muli:300,300i,400,400i,600,600i,800,800i&display=swap');

body {
  min-width: 360px;
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
  font-size: 100%;
  @include p-style;
}

a img {
  border: none;
}

a:link,
a:visited,
a:focus {
  text-decoration: none;
  border: none;
  outline: none;
}

h1 {
  @include h1-style;
}

h2 {
  @include h2-style;
}

h3 {
  @include h3-style;
}

h4 {
  @include h4-style;
}

.chapeau {
  @include chapeau;
}

/***
Page
***/

#page-title {
  z-index: 0;
  text-align: center;
  background-color: #f5f5f5;
}

#page-title h1 {
  margin: 0;
  padding: 27px 15px;
  @include font-size(1.4375rem);
  font-weight: 300;
  text-transform: uppercase;
  color: $txOrange;
}

.no-banner {
  display: none;
}

#page-subtitle h2 {
  margin: 20px 0 25px;
  @include font-size(1.3rem);
  line-height: 1.4rem;
  font-weight: 300;
  text-align: center;
  color: $txBlue;
}


.field-error-message {
  color: $txOrange;
}

