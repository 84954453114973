@import "functions";
@import "mixin";
@import "common";
@import "guid/guid";

// Components
@import "header";
@import "footer";
@import "transition";
@import "loading";
@import "google";
@import "fields/email";
@import "fields/phone";

//@import "stripe";
@import "identity";
@import "form";
@import "tabs";
@import "button-line";
@import "product-selector";
@import "toolbar";
@import "page";

// Pages

@import "analyses";
@import "why";
@import "press";
@import "contact";
@import "order";
@import "legal";
@import "home";
@import "about";
@import "account";
@import "activation";
@import "checkout";
@import "countries";
@import "404";
@import "paiement";

@import "resp-xs";
@import "resp-sm";
@import "resp-md";
@import "resp-lg";
@import "resp-xl";

@import "ribbon";
@import "dialog";
@import "chip";
@import "create-account-form";
@import "translation-page";

@import "themes/veterinary";
@import "fields/payment-methods";
@import "article";
@import "paypal";
@import "information-ribbon";

.App {
  overflow: hidden;
}

//@import "not-mobilefirst"; vide!?

// Déjà en place
/*@import "account";
@import "fields";
@import "common";
@import "activation";
@import "notfound";
@import "payment";
@import "orders";
@import "contact";
@import "autocomplete";
@import "form";
@import "country";
@import "section";
@import "footer";
@import "products";
@import "recover";*/

// Responsive
