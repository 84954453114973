
.identity-thumbnail {
  display: flex;
  flex-flow: row wrap;
  //height: 100px;
  justify-content: space-between;
  height: auto;
  align-content: center;
  width: 100%;
}

.identity-thumbnail-data {
  display: flex;
  flex-direction: column;
}
.identity-selector-form {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  height: auto;
}

.identity-thumbnail-fullname {
  //width: 300px;
}
.identity-thumbnail-email {
  //width: 300px;
}

.identity-btn-disconnect {
  width: fit-content;
  justify-self: center;
  align-self: center;
  margin-top: 10px;
}

.identity-selector-create-form-item {
  margin: 5px;
}

.identity-form-block-fullname {
}

.identity-form-block-firstname {
  width: 100%;

}

.identity-form-item {
  width: 100%;
}

.identity-civility {
}

.identity-firstname {
  width: 100%;
}

.identity-lastname {
  width: 100%;
}

.identity-phone input {
  padding: 6px 12px;
}
