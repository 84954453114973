
.payment-method-container {
   list-style: none;
  .field-error-control {
    width: 100%;
  }
  .payment-method-item {
    display: flex;
    flex-flow: row;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid transparent;
    transition: all 0.2s linear;

    &.card {
      img {
        max-width: 25%;
        @include media-breakpoint-down(sm) {
          max-width: 60%;
        }
      }

    }
    &.sepa {
      img {
        max-width: 8%;
        @include media-breakpoint-down(sm) {
          max-width: 20%;
        }
      }
    }
    &:hover {
      border: 1px solid $txBlue;
      transition: all 1s linear;
    }
    margin-bottom: 3rem;
    align-items: flex-start;
    justify-content: flex-start;


    .form-check {
      display: flex;
      flex-flow: row;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      label {
        width: 100%;
        margin: 0;
        cursor: pointer!important;
        display: flex;
        flex-flow: row;


        @include media-breakpoint-down(sm) {
          flex-flow: column;
          .payment-method-name {
            margin-bottom: 10px;
          }
          .payment-method-logo {
            justify-self: center;
            margin-left: auto;
            margin-right: auto;
          }
        }
        @include media-breakpoint-down(xs) {
          flex-flow: column;

          .payment-method-logo {
            justify-self: center;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
          }
        }
        .payment-method-logo {
          justify-self: flex-end;
          margin-left: auto;
        }
      }
    }

    .payment-method-label {
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      align-items: center;
                }

    input {
      margin-right: 10px;
    }
  }
}
