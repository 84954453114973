@include media-breakpoint-up(md) {

  /***
  Header
  ***/



  .site-logo {
    display: block;
    padding: 9px 0;
    height: 100%;
  }

  .default-logo {
    height: 100%;
  }

  .mobile-logo {
    display: none;
  }

  /***
  Site nav
  ***/
  .burger,
  #menu2 {
    display: none;
  }
  #site-menu {
    text-align: right;
    li {
      display: inline-block;
    }
    a {
      padding: 0 10px;
      display: block;
      height: 70px;
      line-height: 70px;
    }
  }

  /***
  Why
  ***/
  #why-banner {
    height: 280px;

    h1 {
      width: auto;

      span {
        width: auto;
      }
    }
  }

  #why-section-1 {

    .chapeau {

      &::after {
        display: none;
      }
    }
  }

  #why-section-2 {
    div div div:last-child {
      padding-top: 100px;
      border-left: 1px solid $txOrange;
    }
  }

  #why-section-6 {
    div div div:last-child {
      padding-top: 100px;
      border-left: 1px solid $txOrange;
    }

    margin-bottom: 70px;
  }

  #why-section-7 {
    padding-bottom: 0;

    h2 {
      text-align: left;
    }

    .cadre {
      position: relative;
      margin-left: 200px;
      padding-left: 80px;
    }

    .text p {
      margin-bottom: 0;
    }

    .illustration {
      position: absolute;
      left: -201px;
      top: 50%;
      margin-top: -122px;
      text-align: left;
    }
  }

  #why-section-8 {

    .last {
      margin-bottom: 40px;
    }
  }

  /***
  Analyses
  ***/
  #page-title {

    &.analyses-page-title {

      h1 {
        font-size: pxrem(20);
        line-height: pxrem(20);
        letter-spacing: pxrem(.13);
      }
    }
  }

  .analyses-insert-2 {
    margin-top: 57px;
  }

  .analyses-page-banner {

    > .container {
      position: relative;
      height: 270px;
      padding-bottom: 30px;
      background-position: 65% 100%;
      background-size: 1400px;
    }

    &-content {
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 50%;

      img {
        width: 200px;
      }

      p {
        text-align: left;
      }
    }
  }

  .info-tabs {

    .tabs-header {

      .tabs-header-item {
        width: 24%;
      }
    }
  }
}

@include media-breakpoint-only(md) {

  #why-section-3 {
    margin-bottom: 190px;

    > .container {
      position: relative;

      .vulnerable-illustration {
        position: absolute;
        top: 100%;
        display: flex;
        align-items: center;
        width: auto;
        margin: -199px 0 0;

        img {
          width: 360px;
          max-width: none;
        }

        .note {
          position: relative;
          top: 35px;
          flex: 1;
          order: -1;
          padding-right: 15px;
          text-align: right;
        }
      }

      .vulnerable-text-2 {
        padding: 0 360px 30px 0;
      }
    }
  }

  #why-section-4 {

    .chapeau {
      padding-right: 220px;
      text-align: left;
    }
  }

  .functioning {

    > .row {
      padding: 0 35px;
    }
  }
}
