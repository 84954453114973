/***
Footer
***/

#site-footer {
  margin-top: 100px;
  color: #707070;
}

#site-footer a {
  display: inline-block;
  color: $txBlue;
}

#site-footer a:hover,
#site-footer a.active {
  // font-weight: 800;
  //color: $txHoverColor;
  text-decoration: none;
}

#site-footer ul {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

#site-footer .social {
  @include font-size(1.5rem);
  text-align: right;
}

#site-footer .social > ul {
  display: inline-block;
  padding-left: 30px;
  background-color: #f5f5f5;
}

#site-footer .social li {
  display: inline-block;
  margin-left: 15px;
}

#site-footer .social a {
  display: inline-block;
  width: 2.4rem;
  height: 2.4rem;
  padding-top: .4rem;
  text-align: center;
  color: #707070;
  border: 1px solid #707070;
  border-radius: 1.2rem;
}

#site-footer .social a:hover {
  color: $txBlue;
  border-color: $txBlue;
}

#site-footer-top {
  padding: 30px 0;
  background-color: #f5f5f5;
}

#site-footer-logo .logo {
  display: inline-block;
  padding-right: 30px;
  background-color: #f5f5f5;
}

#site-footer-menus {
  // padding-top: 15px;
  // padding-bottom: 15px;
  @include font-size(0.875rem);
  text-transform: uppercase;
  border-top: 1px solid $orange;
  border-bottom: 1px solid $orange;
}

#site-footer-menus ul {
  margin-bottom: 0;
}

#site-footer-menus a {
  display: inline-block;
  padding: 3px 0;
}

#site-footer-mail {
  margin-top: 30px;
  margin-bottom: 0;
  @include font-size(0.6875rem);
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

#site-footer-mail li {
  text-align: center;
}

#site-footer-mail a {
  color: #707070;
}

#site-footer-mail span {
  @include font-size(1.1875rem);
}

#site-footer-legal {
  margin-top: 30px;
  text-align: center;
  @include font-size(.625rem);
  line-height: 20px;
}

#site-footer-legal .wrapper {
  margin-top: 10px;
  border-top: 1px solid $orange;
}

#site-footer-legal ul {
  position: relative;
  display: inline-block;
  height: 20px;
  top: -10px;
  margin: 0 -15px;
  padding: 0 15px;
  background-color: #f5f5f5;
}

#site-footer-legal li {
  display: inline-block;
  margin: 0 15px;
}

#site-footer-credits {
  text-align: center;
  @include font-size(.625rem);
}

@include media-breakpoint-down(md) {
  #site-footer-menus {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 30px;
  }
}
