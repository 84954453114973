$button-height: 38px;
$button-radius: 19px;

.ts-button-group {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    margin: 2%;
  }
  @include media-breakpoint-down(sm) {
    flex-flow: column nowrap;
    button {
      margin-bottom: 10px;
      min-width: 50%;
    }
  }
}

.ts-button {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  vertical-align: middle;
  height: $button-height;
  border-radius: $button-radius;
  padding: 0 $button-radius;
  text-transform: uppercase !important;
  border-width: 1px!important;
  border-style: solid!important;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.ts-blue {
    border-color: $txBlue !important;
    background-color: $txBlue !important;
    color: white !important;
    &:disabled {
      border-color: $txGreyTwo!important;
      background-color: $txGreyOne!important;
    }
    &:hover:not([disabled]) {
      background-color: white !important;
      color: $txBlue !important;
    }

    &-light {
      border-color: $txBlue !important;
      background-color: white !important;
      color: $txBlue !important;
      &:disabled {
        border-color: $txGreyOne!important;
        color: $txGreyOne!important;
      }
      &:hover:not([disabled]) {
        background-color: $txBlue !important;
        color: white !important;
      }
    }
  }

  &.ts-orange {
    border-color: $txOrange !important;
    background-color: $txOrange !important;
    color: white !important;

    &:hover:not([disabled]) {
      background-color: white !important;
      color: $txOrange !important;
    }

    &-light {
      border-color: $txOrange !important;
      background-color: white !important;
      color: $txOrange !important;
      &:hover:not([disabled]) {
        background-color: $txOrange !important;
        color: white !important;
      }
    }
  }

  &.ts-grey {
    border-color: $txGreyOne !important;
    background-color: $txGreyOne !important;
    color: white !important;

    &:hover:not([disabled]) {
      background-color: white !important;
      color: $txGreyOne !important;
    }

    &-light {
      border-color: $txGreyOne !important;
      background-color: white !important;
      color: $txGreyOne !important;
      &:hover:not([disabled]) {
        background-color: $txGreyOne !important;
        color: white !important;
      }
    }
  }
}


.ts-button-blue-light {
  @extend .ts-button, .ts-blue-light;
}
.ts-button-blue {
  @extend .ts-button, .ts-blue;
}
.ts-button-orange-light {
  @extend .ts-button, .ts-orange-light;
}
.ts-button-orange {
  @extend .ts-button, .ts-orange;
}
.ts-button-grey-light {
  @extend .ts-button, .ts-grey-light;
}
.ts-button-grey {
  @extend .ts-button, .ts-grey;
}

.ts-button-dialog-default {
  @extend .ts-button-blue;
}

.ts-button-order-validation {
  @extend .ts-button-blue;
}

.ts-button-checkout {
  margin-left: auto;
  @extend .ts-button-blue;
}

.ts-button-product-count {
  @extend .ts-button-grey;

  color: white;
  span {
    position: absolute;
    //margin: auto;
    //line-height: 0;
    font-size: 21px;
  }

}
.ts-button-prescriber-validation {
  @extend .ts-button-blue-light;
}

.ts-button-order-logout {
  @extend .ts-button-blue-light;
}

.ts-button-thn-login {
  @extend .ts-button-blue;
  margin: auto;
  &-back {
    margin: auto;
    @extend .ts-button-blue;
  }
  &-connecting {
    margin: auto;
    @extend .ts-button-blue;
  }
  &-ok {
    margin: auto;
    @extend .ts-button-blue;
  }
}

.ts-button-login {
  margin: auto;
  @extend .ts-button-blue;
}

.ts-button-create-account {
  @extend .ts-button-blue;

  &-submit {
    @extend .ts-button-blue;
  }
  &-cancel {
    @extend .ts-button-blue;
  }
}



.ts-button-product-code {
  &-edit {
    @extend .ts-button-blue;
  }
  &-validate {
    @extend .ts-button-blue;
  }
}
.ts-button-activation {
  &-logout {
    @extend .ts-button-blue;
  }
  &-login {
    @extend .ts-button-blue;
  }
  &-validate {
    @extend .ts-button-blue;
  }
}

.ts-button-contact-submit {
  @extend .ts-button-blue;
}

.ts-button-order-sheet {
  @extend .ts-button-blue;
}

.ts-button-download {
  @extend .ts-button-blue;
}

.ts-button-download-metal {
  margin: auto;
  @extend .ts-button-download;
}

.ts-button-download-integral {
  margin: auto;
  @extend .ts-button-download;
}

.ts-button-download-organic {
  margin: auto;
  @extend .ts-button-download;
}

.ts-button-home-order {
  margin: auto;
  @extend .ts-button-orange-light;
}

