.about-banner {
  position: relative;
  height: 350px;
  padding-top: 175px;
  background: #f5f5f3 url('/images/aboutus/a-propos-1.jpg') no-repeat 100% 50%;
  background-size: cover;
  margin-bottom: 50px;
  text-align: left;

  h1 {
    text-align: left;
  }

  p {
    text-align: left;
  }
}

.content-about {
  h4 {
    text-align: center;
  }

  .background-toxseek-structure-orange {
    margin: auto;
    width: 100%;
  }

  .group-tokseek {
    text-align: center;
    max-width: 800px;
    margin: 70px auto 80px auto;

    p{
      margin-top: 0;
    }

    span a{
      color: $txBlue;
    }

    .header {
      h4 {
        color: $txOrange;
        font-size: 1.7em;
        font-weight: 300;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing:1.84px;
      }

      p {
        font-size: 1.2em;
        line-height: 1em;


      }
    }

    > div {
      margin-bottom: 40px;
    }

    h4 {
      font-weight: 600;
    }

    h5 {
      color: $txOrange;
    }
  }

  .comite {

    hr {
      height: 100px;
      border-left: 1px solid $txBlue;
      position: relative;
      margin-top: -100px;
      text-align: center;
      /* display: flex; */
      /* justify-content: center; */
      width: 1px;
    }

    .header {
      text-align: center;
      margin: 40px auto 20px auto;

      h4 {
        color: #f13c11;
        line-height: 1em;
        font-size: 1.7em;
        font-weight: 300;
        margin-bottom: 20px;
        text-transform: uppercase;
        letter-spacing:1.84px;
      }

      p {
        font-size: 1.2em;
        line-height: 1em;
        max-width: 600px;
        margin: 20px auto 60px auto;
      }
    }

    .docteur {
      display: flex;
      margin-bottom: 40px;

      h4 {
        width: 40%;
        text-align: right;
        margin-right: 40px;
      }

      p {
        width: 60%;
        text-align: left;
        margin-top: 0;
      }
    }


  }

  .grey-block.comite{
    padding: 50px 50px 50px 80px;
  }

  .end {
    margin: 80px auto 0 auto;

    h4 {
      text-align: center;
      color: $txOrange;
      font-size: 1.6em;
    }
  }

}

.block-with-orange-line {
  margin: 80px 0 40px 0;

  .block-left {
    border-right: 1px solid $txOrange;
    padding: 0 20px 0 20px;
  }

  .block-right {
    padding: 40px 20px 0 20px;
  }
  p:last-child{
    margin-bottom:0;
  }

}

@include media-breakpoint-down(md) {

  .about-banner {
    position: relative;
    height: 350px;
    padding-top: 175px;
    background: #f5f5f3 url('/images/aboutus/a-propos-1.jpg') no-repeat 100% 50%;
    background-size: cover;
    margin-bottom: 50px;
    text-align: left;
    background-position-x: 50%;

    h1 {
      text-align: left;
    }

    p {
      text-align: left;
      max-width: 250px;
      font-size: 1.1em;
    }
  }

  .comite {
    .header {

      h4 {
        //font-size: 2em!important;
        line-height: 1.3em!important;
      }

      p {
        font-size: 1.2em!important;
      }
    }

    .docteur {
      display: block!important;

      h4 {
        width: 100%!important;
        text-align: left!important;
      }

      p {
        width: 100%!important;
        text-align: left!important;
      }
    }
  }


  .content-about{

    .group-tokseek {
      margin: 60px auto 80px auto;

      h5 {
        padding: 0 160px;
      }

      .banner-text {
        padding: 0 50px;
      }
    }
    .grey-block.comite {
      padding: 50px 50px 50px 50px;
    }

  }
}

@include media-breakpoint-down(sm) {
  .block-with-orange-line {
    margin: 40px 0;


    h4 {
      text-align: left!important;
    }

    hr {
      border-top: 1px solid $txOrange;
      width: 50%;
      text-align: center;
      margin: 40px auto;
    }

    .block-left {
      border-right: none;
      padding: 20px 20px 20px 20px;
    }

    .block-right {
      padding: 20px 20px 20px 20px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .content-about{
    .group-tokseek {
      margin: 0px auto 80px auto;

      h5 {
        padding: 10px 60px 30px 60px;
      }

      .banner-text {
        padding: 0 30px;
      }
    }
    .grey-block.comite {
      padding: 50px 30px 30px 30px;
    }
  }

  .end {
    margin: 80px auto 0 auto;
    padding: 0 20px;

    h4 {
      text-align: center;
      color: $txOrange;
      font-size: 1.6em;
      line-height: 1.2em;
    }
  }

}

@include media-breakpoint-down(xs) {
  .about-banner {
    position: relative;
    height: 350px;
    padding-top: 175px;
    background: #f5f5f3 url('/images/aboutus/a-propos-1.jpg') no-repeat 60% 50%;
    background-size: cover;
    margin-bottom: 50px;
    text-align: left;
    display: flex;
    align-items: flex-end;

    h1 {
      text-align: left;
      font-size: 1.6em;
      width: 150px;
    }

    p {
      text-align: left;
      max-width: 250px;
      //font-size: 1.5em;
    }
  }

  .content-about{
    .group-tokseek {
      margin: 0px auto 80px auto;

      h5 {
        padding: 10px 20px 30px 20px;
      }

      .banner-text {
        padding: 0 20px;
      }
    }

    .grey-block.comite {
      padding: 50px 30px 20px 30px;
    }
  }

  .block-with-orange-line {
    margin: 0px 0 40px 0;}

}