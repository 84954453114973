

.modal-header{
  .close {
    position: absolute;
    right: 1rem;
  }
}

.modal-title {
  font: 300 19px/20px Muli;
  color: $txOrange;
  text-align: center;
  margin-left: auto;
  margin-right: auto;


}

.modal-body {

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  text-align: center;
  p {
    font: 300 15px/19px Muli;

    padding-bottom: 20px;

  }

  button {
    min-width: 30%;
  }


}

