

.not-found-page {

  h2 {
    text-align: center;
  }
  .img-container {
    @extend .container;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background-image: url("/images/404/404.jpg");
    background-position: center;
    background-size: cover;
    height: 50vh;
    .img-item {

      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      max-width: 20%;
      img {
        max-width: 50%;
        max-height: 100%;
      }
      &.left {
        text-align: right;
      }
      &.right {
        text-align: left;
      }
      //margin: 0 10%;
    }

  }


}
