#page-title {

  &.analyses-page-title {
    background-color: #ddd;

    h1 {
      font-size: pxrem(15);
      line-height: pxrem(17);
      letter-spacing: pxrem(.1);
      text-transform: none;
      font-weight: 400;
      color: $txBlue;
      background-color: #ddd;
    }

    em {
      display: block;
      font-style: normal;
      color: $txGreyOne;
    }
  }
}

.analyses-page-banner {
  background-color: #e5e5e5;
  margin-bottom: 100px;

  > .container {
    position: relative;
    height: 400px;
    padding-top: 30px;
    padding-bottom: 300px;
    background: url("/images/products/kit-produit-pano.png") no-repeat 80% 100%;
    background-size: 1000px;

    &.integral{
      background: url("/images/products/kit-produit-integral-pano.png") no-repeat 65% 100%;

      @include media-breakpoint-down(md) {
        background-size: 186%;
      }

      @include media-breakpoint-down(sm) {
        background: url("/images/products/kit-produit-integral-pano.png") no-repeat 74% 100%;
        background-size: 231%;
      }

      @include media-breakpoint-down(xs) {
        background: url("/images/products/kit-produit-integral-pano.png") no-repeat 81% 97%;
        background-size: 254%;
      }
    }
  }

  &-content {
    text-align: center;
    color: $txBlue;

    img {
      width: 184px;
    }

    p {
      margin-top: 15px;
    }

    em {
      font-style: normal;
      color: $txGreyOne;
    }
  }

  .order-button-1 {
    position: absolute;
    right: 0;
    bottom: -2.4rem;
  }
}

.tabs-products {
  > .tabs-header {
    .tabs-header-item {

      @include media-breakpoint-only(xs) {
        // width: 75px;
        width: 33%;
      }
    }
  }
}

.analyses-section {
  margin-bottom: 70px;
  .kit-toxseek {
    position: relative;
    width: 100%;
    padding-top: 40px!important;
    min-height: 210px;


    .product-order {
      position: absolute;
      right: 0;
      bottom: -38px;
      flex-flow: column nowrap;
      justify-content: flex-end;
      .product-order-price {
        margin-right: 10px;
      }
      @include media-breakpoint-down(md) {
        position: relative;

      }
    }
  }

  .kit-toxseek-note {
    margin-top: 20px!important;
  }

}


.analyses-insert {
  margin: 5px 0;
  padding: 40px 10px;
  text-align: center;
  color: $txBlue;
  border: 1px solid $txOrange;

  &-1 {
    padding: 40px 60px;
  }

  &-2 {
  }

  h2 {
    @include h3-style;
    margin-bottom: 20px;

    span {
      display: block;

      /*&:before {
        content: '';
        display: block;
        width: 5px;
        margin: 10px auto;
        border: 1px solid $txOrange;
      }*/
    }
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li:not(:last-child)::after {
      content: '';
      display: block;
      width: 4px;
      margin: 10px auto;
      border-bottom: 1px solid $txBlue;
    }
  }
}

.info-tabs {

  .tabs-header {
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fbfbfb;

    .tabs-header-item {
      white-space: nowrap;
      font-size: pxrem(15);
      text-transform: none;
      color: $txBlue;

      &:hover,
      &.active {
        background-color: $txGreyTwo;
      }

      &:hover span {
        color: $txBlue;
      }

      &.active span {
        font-weight: 700;
        color: $txBlue;
      }
    }

    @include media-breakpoint-only(xs) {
      .tabs-header-item:last-child {
        // margin: 0 auto;
        width: 100%;
      }
    }
  }
}

.functioning {
  margin-bottom: 120px;

  .specimen{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color:#707070;
    font-size: 0.875rem;
    margin: 10px 5px;
    line-height: 1rem;
    img{
      max-width:60px;
      margin-right:15px;
      border:0.5px solid #e5e5e5;
    }

  }

  h2 {
    margin-bottom: 45px;
    @include h3-style;
  }

  .functioning-step {
    position: relative;
    margin-bottom: 50px;
    padding: 60px 15px 40px;
    text-align: center;
    color: $txBlue;
    background-color: $txGreyTwo;

    &::before {
      position: absolute;
      top: -30px;
      left: calc(50% - 30px);
      z-index: 100;
      display: block;
    }

    &-1::before {
      content: url('/images/products/rond-tube.png');
    }

    &-2::before {
      content: url('/images/products/rond-microscope.png');
    }

    &-3::before {
      content: url('/images/products/rond-rapport.png');
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      bottom: -20px;
      height: 30px;
      border: 1px solid $txBlue;
    }

    &.last::after {
      display: none;
    }
  }
}


.analyses-accordion {

  h2 {
    margin-bottom: 30px;
    @include h3-style;
  }

  .accordion-item {

    &-header {
      font-size: pxrem(15);
      color: $txBlue;
      cursor: pointer;

      &:hover,
      &.active {
        font-weight: 600;
      }
    }

    &::after {
      content: '';
      display: inline-block;
      width: 108px;
      margin: 15px 0;
      border: 1px solid $txOrange;
    }
  }
}

.info-tabs {
  background-color: $txGreyTwo;

  .tabs-content {
    padding: 15px 0;
  }
}

.block-special-product {
  max-width: 800px;
  margin: 90px auto 50px auto;



  .kit-toxseek-note {
    //text-align: center;
    margin: auto;
    padding: 0 0 40px 0;
    color: $txBlue;
    width: 600px;
    font-size: 0.8em;
    margin:0;

    p{
      margin:0;
    }
  }

  .kit-toxseek-integral {
    background: url("/images/products/kit-toxseek-pano2.jpg") no-repeat bottom right;
    background-size: cover;
    padding: 20px;

    &.integral{
      background: url("/images/products/kit-toxseek-integral2.jpg") no-repeat bottom right;
      background-size: cover;
      padding: 20px;
    }



    .product-order {
      //text-align: left!important;
    }

    img {
      width: 200px;
      margin-left:40px;
    }
  }
}

@include media-breakpoint-down(md) {
  .functioning {
    margin-bottom: 80px;

    .specimen{
      justify-content:left;
      margin: -15px 0 0 0;
      font-size:1rem;
      line-height: 1.2rem;
      width: 80%;
    }


  }

  .functioning-step-3 {
    margin-bottom: 20px!important;
  }


  .block-special-product {
    max-width: 100%;
    margin: 90px auto 50px auto;

    .kit-toxseek-note {
      text-align: left;
      margin: 0;
      padding: 20px;
      color: $txBlue;
      width: 500px;
    }

    .kit-toxseek-integral {
      background: url("/images/products/kit-toxseek-pano.png") no-repeat bottom right;
      background-size: cover;
      padding: 20px 60px;
      height: 250px;
      position: relative;
      display: flex;
      align-items: center;

      &.integral{
        background: url("/images/products/kit-produit-integral-pano.png") no-repeat bottom right;
        background-size: cover;
      }

      .product-order {
        text-align: right!important;
        position: absolute;
        right: 0;
        //bottom: -46px;
      }

    }
  }
}

@include media-breakpoint-down(sm) {
  .functioning {
    margin-bottom: 60px;
  }

  .functioning-step-3 {
    margin-bottom: 20px!important;
  }


  .block-special-product {
    max-width: 100%;
    margin: 90px auto 50px auto;

    .kit-toxseek-note {
      width: 60%;
    }

    .kit-toxseek-integral {
      background: url("/images/products/kit-toxseek-carre.jpg") no-repeat center bottom;
      background-size: cover;
      padding: 20px 60px;
      height: 350px;
      position: relative;
      text-align: center;
      display: block;

      &.integral{
        background: url("/images/products/kit-toxseek-integral2-carre.jpg") no-repeat center bottom;
        background-size: cover;
      }

      img{
        margin-top:-20px;
        margin-left:0px;
      }

      .product-order {
        text-align: right!important;
        position: absolute;
        right: 0;
        //bottom: -46px;
      }

    }
  }

  .analyses-insert-1 {
    margin-left: 0;
    padding: 20px 40px;
  }

  .analyses-insert-2 {
    margin-right: 0;
    padding: 20px 40px;
  }
}

@include media-breakpoint-down(xs) {
  .analyses-insert-1 {
    margin-left: 0;
  }

  .analyses-insert-2 {
    margin-right: 0;
  }

  .block-special-product {
    margin: 90px auto 50px auto;

    .kit-toxseek-note {
      width: 100%;
      padding-top:50px;

      p{
        line-height: 0.9rem;
      }
    }
  }
  .functioning{
    .specimen{
      width:100%;
      font-size: 0.875rem;
     }
  }

}

