.wrapper-article{
  max-width:1000px;
  margin-left: auto;
  margin-right: auto;
  @include media-breakpoint-down(lg) {
    max-width:900px;
  }
  @include media-breakpoint-down(md) {
    max-width:700px;
  }
  @include media-breakpoint-down(sm) {
    max-width:530px;
  }
  @include media-breakpoint-down(xs) {
    max-width:320px;
  }

  h1{
    font: normal normal 700 36px/39px Muli;
    margin-bottom:20px;
    letter-spacing: 0;
    color:#000000;
    @include media-breakpoint-down(md) {
      font: normal normal 700 30px/33px Muli;
    }
    @include media-breakpoint-down(sm) {
      font: normal normal 700 25px/29px Muli;
      margin-bottom:10px;
      margin-top:20px;
    }
    @include media-breakpoint-down(xs) {
      font: normal normal 700 22px/24px Muli;

    }
  }

  h2{
    font: normal normal 700 20px/26px Muli;
    color:#4C4C4C;
    //margin-bottom:0px;
    margin-top:40px;
    @include media-breakpoint-down(md) {
      font: normal normal 700 19px/26px Muli;
    }
  }

  h3{
    font: normal normal 700 15px/21px Muli;
  }

  h4{
    font: normal normal 700 15px/21px Muli;
    color:$txOrange;
  }

  h5{
    font: normal normal 700 15px/21px Muli;
    text-transform: uppercase;
  }

  hr {
    height: 50px;
    border-left: 1px solid $txOrange;
    position: relative;
    margin-top: 0;
    text-align: center;
    width: 1px;
    @include media-breakpoint-down(xs) {
      height: 30px;
    }
  }

  .current-text{
    font: normal normal normal 15px/21px Muli;
    @include media-breakpoint-down(sm) {
      max-width:530px;
    }
  }

  .center{
    text-align:center;
  }

  .orange{
    color:$txOrange;
  }

  .bold{
    font-weight:700;
  }

  .no-margin{
    margin:0;
  }

  .title{
    font: normal normal 700 15px/21px Muli;
    margin-top:15px;
  }


  .country{
    text-align: center;
    margin-top:0;
    font: normal normal normal 15px/21px Muli;
    @include media-breakpoint-down(md) {
      font: normal normal normal 14px/19px Muli;
    }
    @include media-breakpoint-down(sm) {
      font: normal normal normal 13px/19px Muli;
    }
  }
  .bold-center{
    text-align:center;
    font-weight:700;
  }

  figcaption{
    font: normal normal 400 12px/14px Muli;
    margin-top:10px;

  }
  .legend{
    font: normal normal 400 13px/19px Muli;
    border-top:solid 1px #676766;
    border-bottom:solid 1px #676766;
    margin:50px 0;
    padding:5px 0;
  }

}

a:link{
  text-decoration:none;
  color:#676766;
}



nav.breadcrumb{
  margin-top:100px;
  background-color: #ffffff;
  padding-left:0;
  @include media-breakpoint-down(sm) {
    display:none;
  }


  a{
    text-decoration: none;
    color:#676766;
  }
}
.breadcrumb ul {
  display: flex;
  flex-flow: wrap;
  list-style-type: none;
  font: normal normal 500 12px/13px Muli;
  padding:0;
  @include media-breakpoint-down(sm) {

  }
}

.breadcrumb li::before {
  content: ">";
  margin:0 5px;
}

.breadcrumb li:first-child::before {
  content: "";
}

//----------------section LOGO ----------------//

.section-logo{
  margin-top:50px;
  margin-bottom:50px;
  @include media-breakpoint-down(xs) {
    margin-bottom:0px;
  }

  .banner-grey{
    background-color: #F7F7F7;
    width:1000px;
    height: 90px;
    margin:auto;
    @include media-breakpoint-down(lg) {
      width:900px;
    }
    @include media-breakpoint-down(md) {
      height: 77px;
      width:700px;
    }
    @include media-breakpoint-down(sm) {
      height: 60px;
      width:530px;
    }
    @include media-breakpoint-down(xs) {
      width:100%;
    }
    img{
      width:auto;
      height:91px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding:16px 0;
      @include media-breakpoint-down(md) {
        height:80px;
      }
      @include media-breakpoint-down(sm) {
        height:63px;
        padding:11px 0;
      }
    }
  }

  .text-banner{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(md) {
      font: normal normal normal 14px/16px Muli;
      margin-top:7px;
    }
    @include media-breakpoint-down(xs) {
      flex-flow: column nowrap;
      font: normal normal normal 12px/14px Muli;

    }
    .square-orange{
      width:10px;
      height:10px;
      background-color:$txOrange;
      margin-right:10px;
      margin-left:10px;
      @include media-breakpoint-down(xs) {
        margin:0;
      }
    }
    p{
      margin-top:5px;
      margin-bottom:5px;
    }
  }

}

//----------------section INTRO ----------------//

.section-article-intro{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  margin-bottom:20px;
  @include media-breakpoint-down(md) {
    flex-flow: column nowrap;
  }

  .one-col-text{
    margin-right:2%;
    //max-width:55%;
    @include media-breakpoint-down(md) {
      max-width:100%;
    }

    .intro-text{
      text-align:center;
      font: normal normal 500 20px/26px Muli;
      @include media-breakpoint-down(md) {
        font: normal normal 500 18px/24px Muli;
      }
      @include media-breakpoint-down(sm) {
        font: normal normal 500 16px/20x Muli;
      }
    }
  }

  .col-img{
    @include media-breakpoint-down(md) {
      width:70%;
      margin:auto;
    }
    @include media-breakpoint-down(xs) {
      width:100%;
    }

    img{
      width:100%;
    }
    .egal{
      width:50%;
    }
  }
}



.square-orange{
  width:10px;
  height:10px;
  background-color:$txOrange;
  margin:auto;
}

.insert-grey{
  background-color: #F7F7F7;
  padding:20px;
  margin:50px 0;
  @include media-breakpoint-down(md) {
    margin:20px 0;
  }
  p{
    margin-top: 0;
  }
}

.insert-white{
  //background-color: #F7F7F7;
  //padding:20px;
  margin:50px 0;
  @include media-breakpoint-down(md) {
    margin:20px 0;
  }
  p{
    margin-top: 0;
  }
}

.two-col{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  @include media-breakpoint-down(sm) {
    flex-flow: column nowrap;
  }
  figure{
    margin-right:20px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom:20px;
      margin-right:0;
    }
    @include media-breakpoint-down(xs) {
      width:80%;
      margin:auto;
      margin-bottom:10px;
    }

    img{
      max-width: 250px;
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }

    figcaption{
      @include media-breakpoint-down(sm) {
        width:80%;
        margin:auto;
        margin-top:5px;
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
  }

  .figure-right{
    margin-left:20px;
    margin-right:0;
    @include media-breakpoint-down(sm) {
      margin-top:20px;
      margin-right:0;
    }
    @include media-breakpoint-down(xs) {
      margin-top:0;
      margin-right:0;
    }
  }
}

.two-col-img{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  @include media-breakpoint-down(sm) {
    flex-flow: column nowrap;
  }
  figure{
    max-width:50%;
    margin-right:20px;
    @include media-breakpoint-down(sm) {
      max-width:100%;
      margin-right:0;
      text-align: center;
    }
    img{
      max-width: 90%;
    }

    figcaption{
      max-width: 90%;
      @include media-breakpoint-down(sm) {
        width: 80%;
        margin: auto;
        margin-top: 5px;
      }
    }
  }
}


.row-inverse{
  @include media-breakpoint-down(sm) {
    flex-direction: column-reverse;
  }
}




.article-two-col-text{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  @include media-breakpoint-down(md) {
    flex-flow: column nowrap;
  }
  p{
    width:50%;
    @include media-breakpoint-down(md) {
      width:100%;
    }

  }
  .reaction-mother{
    width:100%;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }



  .current-text:first-child{
    margin-right:20px;
  }

}

.col-text{
  max-width:50%;
  @include media-breakpoint-down(md) {
    min-width:100%;
  }
  p{
    margin-top:0;
    margin-right:20px;
    @include media-breakpoint-down(sm) {
      margin-right:0;
    }
  }
  figure{
    //margin-right:20px;
    @include media-breakpoint-down(sm) {
      text-align: center;
      margin-bottom:20px;
      margin-right:0;
    }
    @include media-breakpoint-down(xs) {
      //width:80%;
      margin:auto;
      margin-bottom:10px;
    }

    img{
      max-width: 90%;
      @include media-breakpoint-down(xs) {
        max-width:100%;
      }
    }

    figcaption{
      @include media-breakpoint-down(sm) {
        width:80%;
        margin:auto;
        margin-top:5px;
      }
      @include media-breakpoint-down(xs) {
        width:100%;
      }
    }
  }
}

.step{
  font: normal normal 700 18px/21px Muli;
  padding-bottom:8px;
  margin-top:20px;
  border-bottom:1px solid $txOrange;
  @include media-breakpoint-down(md) {
    margin-top:30px;
  }
}
.bloc-border-orange{
  border:1px solid $txOrange;
  padding:20px;
  height: max-content;
  margin-right: 40px;
  max-width: fit-content;
  @include media-breakpoint-down(md) {
    margin-bottom:20px;
  }
  p{
    margin:0;
  }
  h2{
    margin:0;
  }
}

.small-width{
  margin:60px auto;
  max-width:80%;
  p{
    margin:0;}
  h2{
    margin:0;}
  .bloc-border-orange{
    margin-right:0;
    @include media-breakpoint-down(md) {
      margin-bottom:0;
    }
  }
  @include media-breakpoint-down(xs) {
    min-width:100%;
  }
}



.resolution-title{
  font: normal normal 700 18px/21px Muli;
  color: $txOrange;
  text-transform:uppercase ;
}

.reaction-mother-md{
  display: none;
  @include media-breakpoint-down(md) {
    display:block;
    margin-top:20px!important;
    width:100%;
  }
}

.child-title{
  font: normal normal 700 20px/24px Muli;
  color:#000000;
  @include media-breakpoint-down(sm) {
    font: normal normal 700 18px/21px Muli;
  }
}


.tab{
  width: 300px;
  height:max-content;
  background:#ffffff;
  border: solid 1px #000000;
  padding:10px;
  margin-right:20px;
  font: normal normal 400 15px/17px Muli;
  @include media-breakpoint-down(md) {
    margin-right:0;
    width: fit-content;
  }
  @include media-breakpoint-down(sm) {
    margin:auto;
    width: fit-content;
  }
  .tab-title{
    font-weight:700;
    margin-bottom:0;
  }
  p{
    width:100%;
    margin-bottom: 10px;
  }
  p:last-child{
    margin-bottom: 0;
  }
}

.text-line-orange{
  margin:30px 40px 40px 40px;
  border-left: 5px solid $txOrange;
  font: normal normal 700 18px/21px Muli;
  padding-left:20px;
  @include media-breakpoint-down(md) {
    font: normal normal 700 16px/20px Muli;
  }
  @include media-breakpoint-down(sm) {
    font: normal normal 700 15px/19px Muli;
    padding-left:10px;
    margin:30px 0px 40px 20px;
  }
  @include media-breakpoint-down(xs) {
    font: normal normal 700 15px/19px Muli;
    padding-left:10px;
    margin:30px 0 40px 0;
  }
}
.wrapper-article{
  hr {
    height: 70px;
    border-left: 1px solid $txOrange;
    position: relative;
    margin-top: 0;
    text-align: center;
    width: 1px;
    @include media-breakpoint-down(xs) {
      height: 30px;
    }
  }
}


//----------------section article Ktipp ----------------//

.article-ktipp-cheveu{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top:80px;
  background: 0px 48% no-repeat url("/images/articles/ktipp/cheveu.jpg") ;
  background-size: 100%;
  @include media-breakpoint-down(md) {
    background: 0px 45% no-repeat url("/images/articles/ktipp/cheveu.jpg") ;
    background-size: 100%;
    margin-top: 49px;
  }
  @include media-breakpoint-down(sm) {
    background: 0px 50% no-repeat url("/images/articles/ktipp/cheveu.jpg") ;
    background-size: 100%;
  }
  .tab-elements{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    h3{
      //margin-right:15px;
     }
    }
    .blue{
      color:$txBlue;
    }
  .text-hr{
    text-align:center;
    padding:0 30px 10px 30px;

    @include media-breakpoint-down(l) {
      padding:0 15px;
    }
  }
  .pesticides{
    h3{
      margin-top:30px;
    }
    p{
      padding-bottom:10px;
      margin-bottom:0;
      margin-top:0;

    }
    .line-orange{
      width:300px;
      height:1px;
      background-color:$txOrange;
      margin:auto;
    }

    hr {
      height: 90px;
      border-left: 1px solid $txOrange;
      position: relative;
      margin-bottom: 0;
      text-align: center;
      width: 1px;
    }

  }

  .metaux-lourds{
    margin-top:25px;
    h3{
      margin-top:15px;
    }
    p{
      padding-top:10px;
      margin-bottom:0;
      margin-top:0;
    }
    .line-blue{
      width:300px;
      height:1px;
      background-color:$txBlue;
      margin:auto;
    }
    hr {
      height: 90px;
      border-left: 1px solid $txBlue;
      position: relative;
      top: 0;
      text-align: center;
      width: 1px;
      margin-bottom:0;
    }
  }

}

.women{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: top;
  margin-top:80px;
  @include media-breakpoint-down(md) {
    flex-flow: column nowrap;
    width:80%;
    margin: 80px auto;
  }
  @include media-breakpoint-down(sm) {
    width:90%;
    margin: 40px auto;
  }
  @include media-breakpoint-down(xs) {
    width:100%;
  }
  img{
    width:100%;

  }
  p{
    margin:0;
  }
  .woman-text{
    padding:15px;
    p{
      margin-top:5px;
    }
    p:nth-child(2){
      margin-top:0;
      margin-bottom:10px;
    }
  }
  .woman1{
    background-color:#DFE9F5;
    width:95%;
    margin-right:5px;
    @include media-breakpoint-down(md) {
      margin-right:0;
    }
  }

  .woman2{
    background-color:#EFF8B9;
    width:95%;
    margin-left:5px;
    @include media-breakpoint-down(md) {
      margin-top:20px;
    }
  }
}

.discount-ktipp{
  margin:60px auto;
  max-width:80%;
  background-color: #F7F7F7;
  padding:20px;
  @include media-breakpoint-down(md) {
    max-width:100%;
  }
  h2{
    color:#000000;
    margin-top:20px;
  }
}

.order-ktipp{
  border: 1px dashed #000000;

  .fields{
    margin:20px;
    p{
      margin-bottom:10px;
      margin-right: 10px;
    }
    .white-back{
      width:100%;
      padding:5px;
      background-color:#ffffff;
    }
  }
  .checkbox{
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: baseline;

    .linecheckbox{
      display: flex;
      flex-flow: row nowrap;
      align-items: baseline;
      p{
        width:175px;
      }
    }
    .square-checkbox{
      width:10px;
      height:10px;
      border: 1px solid #000000;
      margin-right:10px;

    }
    p{
      margin:0;
    }

  }
  .sending{
    margin:20px 0;
  }
  .kits{
    .quantite{
      @include media-breakpoint-down(xs) {
        margin-left:19px;
      }
  }
    @include media-breakpoint-down(xs) {
      flex-flow: column nowrap;
    }
  }

}

//----------------section article Bon à savoir ----------------//


.bon-a-savoir{
  .section-article-intro{
    margin-top:80px;
  }
  h2{
    text-transform: uppercase ;
    margin-top:0;
    color:$txBlue;
    font: normal normal 700 18px/22x Muli;
  }
  .col-text{
    margin-right: 30px;
  }

  .bloc-border-orange{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
      text-align: center;
    }

    img{
      width: 100%;
      @include media-breakpoint-down(md) {
        width:80%;
        margin:15px auto;
      }
      .img{
        width:100%;
        height:auto;

        }
    }
  }

  .discount{
    margin:60px auto;
    max-width:80%;
    background-color: #F7F7F7;
    padding:20px;
    @include media-breakpoint-down(md) {
      max-width:100%;
    }
    h2{
      color:#000000;
    }
  }

  .order{
    border: 1px dashed #000000;

    .fields{
      margin:20px;
      p{
        margin-bottom:10px;
      }
      .white-back{
        width:100%;
        padding:5px;
        background-color:#ffffff;
      }
    }
  }

}


//----------------section article France Bleu ----------------//






//----------------section Lien bas de page ----------------//

.links-website{
  text-align:center;
  margin-top:110px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  a{
    text-decoration:none;
    color:#999999;
    overflow-wrap: break-word;
  }
  p:nth-child(1){
    margin-top:15px;
  }
  a:hover{
    color: $txOrange;
    font-weight: bold;
  }
  .square-orange{
    margin-bottom:20px;
  }
  .current-text{
    font: normal normal normal 16px/20px Muli;
    margin: 0;
      }
  .current-text:hover{
    font: normal normal bold 16px/20px Muli;
  }
  .line-orange{
    width:50px;
    height:1px;
    background-color:$txOrange;
    margin:20px auto;
  }

}




//----------------section Cover ----------------//

.cover{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top:60px;
  img{
    max-width:173px;
    height:auto;
    padding:10px;
    border:solid 1px #999999;
  }

  img:hover{
    border:solid 1px $txOrange;
  }

  p{
    text-align:center;

  }

  a{
    text-decoration:none;
    text-align:center;
    color:#999999;

  }

  a:hover{
    color: $txOrange;
  }
}
//----------------fin de section Cover ----------------//