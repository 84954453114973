.legal-section h2 {
  margin-top: 60px;
  @include h4-style;
}

p {
  margin: 22px 0;
}


.intro {
  text-align: center;
  margin:10%;
}

