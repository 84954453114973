

.country-selector-link {
  img {
    width: 20px;

    //padding-left: .5px;

  }
  img::after {
    position: absolute;
    top: 24px;
    left: 0;
    width: 20px;
    height: 20px;
    z-index: 1000000;
    content: '->';
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid #d31f43;
  }
}
.page-countries {
  h1 {
    margin-top: 5%;
  }
  h2 {
    text-align: center;
  }
  .section-content {
    margin-top: 5%;
  }
  .continent-container {
    @extend .container;

    margin-bottom: 5%;

    .country-list {
      display: flex;
      flex-flow: row wrap;

      .country-list-item {
        width: 20%;

        @include media-breakpoint-down(md) {
          width: 25%;
        }

        @include media-breakpoint-down(xs) {
          width: 33%;
        }
        margin-bottom: 2%;

        a {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;
          color: $txGreyOne;
          text-transform: capitalize;

          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}






.country-selector-ribbon-container {
  @extend .container-fluid;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: $txGreyOne;
  padding: 0;

  .country-ribbon {
    @extend .container;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    @include media-breakpoint-down(xs) {
      flex-flow: column nowrap;

    }

    .country-selector-ribbon-text {
      @include font-size(0.8rem);
      width: 33%;
      color: white;
      @include media-breakpoint-down(xs) {
        width: 90%;
        padding: 5px;
      }
    }

    .country-selector-ribbon-dropdown-item {
      min-width: 170px;
      text-align: left;
      display: inline-block;
    }

    .country-selector-ribbon-controls {
      display: flex;
      flex-flow: row nowrap;

      @include media-breakpoint-down(xs) {
        width: 100%;

      }
      button {
        margin-left: 4%;
      }
    }
  }


  .country-selector-ribbon-close {
    align-self: flex-start;
    justify-self: flex-start;
    color: white;
    padding: 5px;

  }


}

