/***
Press
***/

article.press > a {
	display: flex;
	height: 300px;
	margin-bottom: 30px;
	padding: 15px;
	@include font-size(.75rem);
	color: $txBlue;
	background-color: #f5f5f5;

}

article.press > a > div {
	flex: 1 1 50%;

}

article.press img {
	max-width: 100%;
}

article.press .illustration {
	display: flex;
	align-items: center;
}

article.press .illustration img {
	padding: 10px 0;
	max-height: 280px;
}

article.press h2 {
	text-align: center;
	margin-top:20px;
}

article.press h2::after {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	margin: 10px auto;
	background-color: #f13c11;
}

article.press .logo {
	position: relative;
	padding-left: 10px;

	h2 {
		margin: 10px;

		img {
			max-width: 120px;
		}
	}
}

article.press .logo p {
	margin: 0;
}

article.press .resume {
	position: absolute;
	bottom: 0;
}
article.press .resume p{
	font-size: 12px;
	line-height: 16px;
}

article.press .readmore {
	@include font-size(.8125rem);
	font-weight: 600;
	color: #f13c11;

}
article.press > a .readmore {
margin-top:10px;
}
article.press > a:hover .readmore {
	text-decoration: underline;
}

@include media-breakpoint-down(md) {
	article.press .resume p{
		font-size: 12px!important;
	}
	article.press > a {
		height: 230px;
	}
}

@include media-breakpoint-down(sm) {
		article.press > a {
		height: 300px;
	}
}

@include media-breakpoint-down(xs) {
	article.press > a {
		height: 230px;
	}

	article.press .resume p{
		line-height: 13px;
	}
}