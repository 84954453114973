
.toolbar {
  height: 32px;

  //background-color: $txGreyTwo;
  .toolbar-container {
    @extend .container;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    .toolbar-item {
      height: 32px;
      padding: 2px 40px;
      color: #707070;
      white-space: nowrap;
    }
      .activation{
       color: $txOrange;
      }
  }

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

