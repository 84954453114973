

.loading-wrapper {
  align-items: center;
  justify-content: center;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  background-color: rgba(128, 128, 128, 0.6);
  z-index: 10000;
  opacity: 1.0;
  text-align: center;
  vertical-align: center;
}
