.product-order {
  // position: relative;
  // top: -3.2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;

  padding-right: 15px;
  text-align: right;
  color: $txBlue;



  &-price {

    span {
      display: block;
    }
  }

  .quantity {
    font-size: pxrem(12);

    @include media-breakpoint-up(sm) {
      font-size: pxrem(13);
    }

    @include media-breakpoint-up(lg) {
      font-size: pxrem(16);
    }
  }

  .product-order-price {
    font-size: pxrem(20);
    line-height: pxrem(25);

    span {
      font-size: pxrem(11);
      line-height: pxrem(14);
    }

    @include media-breakpoint-up(sm) {
      font-size: pxrem(22);
      line-height: pxrem(28);

      span {
        font-size: pxrem(12);
        line-height: pxrem(15);
      }
    }

    @include media-breakpoint-up(lg) {
      font-size: pxrem(24);
      line-height: pxrem(30);

      span {
        font-size: pxrem(14);
        line-height: pxrem(18);
      }
    }
  }

  .product-item-selector-item {
    position: relative;
    width: 3.625rem;
    height: 2.875rem;
    display: inline-block;
    margin-left: .5rem;
    vertical-align: middle;
    text-align: left;
    // background-color: red;

    .fa-minus {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 100;
      width: 1.5rem;
      height: 1.5rem;
      padding-right: .5rem;
      padding-bottom: 1rem;
      color: #fff;
      background-color: #999;
      cursor: pointer;
    }

    .fa-plus {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
      width: 1.5rem;
      height: 1.5rem;
      padding-top: 1rem;
      padding-right: .5rem;
      color: #fff;
      background-color: #999;
      cursor: pointer;
    }

    input {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 10;
      width: 3.625rem !important;
      height: 2.875rem;
      padding: 0px;
      text-align: center;
      background-color: #999;
      color: #fff;
      border: none;
      border-radius: 0;
    }
  }

  .product-item-order-button {
    display: inline-block;
    vertical-align: middle;
    margin-left: .5rem;

    .btn {

      height: 2.875rem;
      line-height: 2rem;
    }
  }
}
