#site-menu
{
  button {
    display: none;
  }
}

@include media-breakpoint-up(sm) {

  /***
  Order
  ***/
  .order-form {
    @include font-size(.8125rem);

    .order-panel {

      h3 {
        @include font-size(.9375rem);
      }

      .product-item {

        &-logo {
          width: 129px;
          height: 51px;
        }

        &-subtitle {
          @include font-size(.625rem);
          @include font-size(.75rem);
          line-height: 125%;
        }

        .order-logodesc {
          display: flex;
        }
      }
    }
  }

  /***
  Why
  ***/
  #why-banner {
    height: 275px;

    h1 {
      overflow: visible;

      span {
        width: 250px;
      }
    }
  }

  #why-section-1 {
    margin-bottom: 70px;

    .chapeau {

      &::after {
        margin-top: 70px;
      }
    }
  }

  #why-section-3 {

    .vulnerable-illustration {
      width: 360px;
      margin: 0 auto;
    }
  }

  #why-section-7 {

    .cadre {
      padding: 40px;
    }

    .illustration {

      img {
        width: 244px;
      }
    }
  }

  #why-section-8 {

    .encart {
      padding: 40px;

      h3 {
        padding-bottom: 10px;
        text-align: left;
      }
    }
  }

  /***
  Analyses
  ***/
  #page-title {

    &.analyses-page-title {

      h1 {
        font-size: pxrem(19);
        line-height: pxrem(20);
        letter-spacing: pxrem(.12);
      }
    }
  }

  .analyses-page-banner {

    > .container {
      height: 406px;
      background-position: 75% 100%;
      background-size: 1250px;
    }
  }
}






@include media-breakpoint-only(sm) {

  #site-footer-menus {
    @include font-size(0.875rem);
  }

}




/***
/!\ NOT MOBILE FIRST
***/


@include media-breakpoint-down(sm) {

  #site-menu
  {
    z-index: 300;
    position: absolute;
    right: 15px;
    height: 100%;
    width: 200%;
    margin: 0 -15px;
    text-align: center;
    // background-color: #f5f5f5;

    button {
      display: block;
      margin: 10px 10px 0 0;
      float: right;
    }

  }

  #site-menu > .liste-menus
  {
    height: 0;
    overflow: hidden;
    text-transform: uppercase;
    background: #fff;
    transition: height .2s, padding-top .2s;
    margin-top: 50px;
  }

  // #site-menu ul > *
  // {
  //   display: block;
  //   width:100%;
  //   padding:0 20px;
  // }

  #site-menu .burger
  {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    position: absolute;
    right: 10px;
    top: 0;
    margin: 0;
    padding: 0;
    font-family: 'Material Icons';
    font-size: 30px;
    line-height: 50px;
    outline: 0;
    text-align: center;
    width: 50px;
    color: inherit;
    cursor: pointer;
    display: none;
  }

  #site-menu .burger:before
  {
    content: 'menu';
  }

  #site-menu .burger:checked:before
  {
    content: 'close';
  }

  #site-menu .burger:checked + .liste-menus
  {
    height: calc(100vh - 50px);
    padding-top: 50px;
  }

  .liste-menus.opened
  {
    height: calc(100vh - 50px)!important;
    padding-top: 50px!important;
  }

  .burger.opened:before
  {
    content: 'close';
  }

  #site-menu li ul a {
    display: block;
    padding: 5px 0;
    text-transform: capitalize;
    color: #707070;
  }
}
