

.splashscreen {
  width: 100%;
  height:300px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
