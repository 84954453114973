

.button-line {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5%;
  @include media-breakpoint-down(xs) {
    flex-flow: column;
    align-items: flex-start;
  }

  .line-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    //width: 100%;
    flex-grow: 1;
    @include media-breakpoint-down(xs) {
      width: 100%;

    }
  }
  .line {
    //width: 100%;
    border-bottom: 1px solid $txBlueButton;
    flex-grow: 1;
    &.left {
      margin: 0 0 0 1%;
    }
  }
  .label, .action{
    width: auto;
    white-space: nowrap;
    font: 600 16px/20px Muli;
    text-transform: uppercase;
    font-size: 16px;
    color: #171796;
    padding-left: 5px;
    @include media-breakpoint-down(xs) {
      font-size: 13px;
    }
  }
}
