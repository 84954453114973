
.tabs {

  .tabs-header-wrapper {
    background-color: #eaeaea;
  }

  .tabs-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }

  .tabs-header-item {
    // height: 47px;
    //padding: 11px 22px;
    padding: 2% 3%;
    font-size: pxrem(13);
    // line-height: 47px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    color: #707070;
    cursor: pointer;

    @include media-breakpoint-up(md) {
      font-size: pxrem(14);
    }

    @include media-breakpoint-up(lg) {
      font-size: pxrem(15);
    }

    @include media-breakpoint-up(lg) {
      padding: 14px 22px;
      font-size: pxrem(16);
    }

    &:hover span {
      color: $txOrange;
      border-bottom: 1px solid $txOrange;
    }

    &.active span {
      color: $txOrange;
      border-bottom: 1px solid $txOrange;
    }
  }
}
