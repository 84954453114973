  .color-blue {
    color: $txBlue;
  }

  .hide-for-mobile {
    display: block;
  }

  .for-mobile {
    display: none;
  }

  #home-banner {
    position: relative;
    height: 350px;
    background: #f5f5f3 url('/images/home/gant-analyse-1246.jpg') no-repeat 100% 50%;
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    h1 {
      max-width: 100%;
    }

    .banner-text {
      max-width: 600px;
    }

    .logo-mobile {
      display: none;
    }

  h1 {
    @include font-size(1.625rem);
    text-transform: uppercase;
    color: $txBlue;
    text-align:left;
  }

}

  .section-1 {
    padding: 20px;

    .baseline-section-1-homepage {
      font-size: 1.125rem;
    }
  }

  .container-kit {
    margin-top: 70px;
    margin-bottom: 70px;
  }

  .block-kit {
    border: 1px solid $txOrange;
    text-align: center;
    height: 220px;
    margin-bottom: 70px;

    .sub-block-kit {
      height: 158px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 21px;
    }

    p {
      color: $txBlue;
      margin: 0;
      min-height: 30px;
      line-height: 1.1;
    }

    .text-grey {
      color: darkgrey;
      font-size: 15px;
      line-height: 1.5;
    }

    .plus {
      color:$txOrange;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      margin-top: -30px;
    }
  }

  .cadre-homepage {
    border: 1px solid $txBlue;
    padding: 70px 150px 70px 70px;
    //margin-bottom: 20px;

    h3 {
      text-transform: uppercase;
      color: $txOrange;
      text-align: left;
      font-size: 1.4em;
      line-height: 1.2em;
      letter-spacing: 3px;
      margin-bottom: 22px;
      font-weight: 300;

    }
    p:last-child{
      margin-bottom: 0;
    }

  }

  .cadre-homepage-2 {
    border: 1px solid $txBlue;
    padding: 40px 50px 20px 130px;
    margin-bottom: 20px;

    h3 {
      text-transform: uppercase;
      color: $txOrange;
      text-align: left;
      font-size: 1.4em;
      line-height: 1.2em;
      letter-spacing: 3px;
    }
  }

  #why-section-6 {
    img {
      max-width: 100%;
    }
    p{margin-bottom:0;}

    .analyse-block {
      border-right: 1px solid $txOrange;
      padding: 0 25px;

      img {
        display: block;
        max-width: 80%;
        margin: 20px auto 40px 0;
      }
      p:nth-child(4){
        margin:0;
        padding:0;
      }
    }

    .analyse-block-2 {
      margin-top: 190px;
      padding: 0 25px;

      img {
        display: block;
        max-width: 80%;
        margin: 20px auto 20px auto;
      }

      p:last-child{
        margin-bottom: 0;
      }
    }

  }

  #why-section-7 {
    .img-why-section {
      margin-left: -100px;
      z-index: 100;
    }

    .img-why-section-2 {
      margin-right: -50px;
      z-index: 100;
    }

    .video-why-section {
      height: 350px;
      margin-left: -130px;
      & > div {
        height: 262px;
        width: 450px;
        margin-top:45px;

      }
    }

    img {
      height: 150%;
      margin-bottom: 20px;

    }
  }



    .qui-somme-nous-title {
      margin-top: 50px;
      padding: 20px;
    }

    .grey-block {
      background-color: #ededed;
      padding: 50px 50px 50px 80px;
      margin-bottom: 20px;

      a {
        color: $txBlue;
      }
    }

    .grey-block.second-item {
      padding-left: 220px;

      img {
        width: 100%;
      }
    }

    .block-qui-somme-nous {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 30px;
    }

    .image-structure-orange {
      position: absolute;
      width: 373px;
      margin-left: -160px;
    }

    .homepage-text-footer {
      color: $txOrange;
      text-align: center;
      font-size: 1.5em;
      margin: 8% 0;
    }

    #press {
      background-color: #f2f2f2;
      padding: 50px;

      .slider-wrapper{
        @extend .container;
      }

      .previous-button-slider {
        //position: absolute;
        //margin-top: 90px;
        margin-left: -20px;
        cursor: pointer;
        font-size: 2em;
        font-weight: bold;

      }

      .next-button-slider {
        //position: absolute;
        //right: 0;
        //margin-top: 90px;
        margin-right: 20px;
        cursor: pointer;
        font-size: 2em;
        font-weight: bold;
      }
      .ts-slider {
        //display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
      }
    }

    .block-slider-press {
      display: flex;
      justify-content: space-around;
    }

    .slick-list {
      margin: 40px;
    }

    .slick-initialized .slick-slide {
      border-right: 1px solid #707070;
    }



    .article-press {
      width: 250px;
      height: 200px;
      padding: 0 20px;
      margin: auto;

      h2 {
        width: 180px;
        text-align: center;
        img {
          display: inline-block!important;
          width: 60%;
        }
      }

      h2::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        margin: 10px auto;
        background-color: $txOrange;
      }

      p {
        color: $txBlue;
        font-size: 13px;
        line-height: 16px;
      }

      .readmore {
        font-size: 0.8125rem;
        font-weight: 600;
        color: $txOrange;
      }
    }




    @media screen and (max-width: 1200px) {
      #home-banner h1 {
        max-width: 500px;
      }
    }

    @include media-breakpoint-down(md) {

      .block-kit {
        .sub-block-kit {
          font-size: 16px;
          height: 176px;
        }

        .text-grey {
          color: darkgrey;
          font-size: 13px;
        }

        img {
          width: 150px;
        }

        button {
          width: 160px;
          font-size: 11px;
          height: 30px;
        }
      }

      #home-banner {
        .banner-text {
          max-width: 400px;
        }
        p{
          max-width: 300px;
        }
      }

      .cadre-homepage {
        padding: 40px 40px 60px 40px;


      }

      .cadre-homepage-2 {
        padding: 20px 20px 20px 20px;
      }

      #why-section-6 {
        img {
          max-width: 100%;
        }

        .analyse-block {
          border-right: 1px solid $txOrange;
          padding: 0 25px;

          img {
            display: block;
            max-width: 80%;
            margin: 20px auto 40px 0;
          }
          p:nth-child(5){
            margin-bottom:50px;
          }
        }

        .analyse-block-2 {
          margin-top: 190px;
          padding: 0 25px;

          img {
            display: block;
            max-width: 80%;
            margin: 20px auto 20px auto;
          }

          p:last-child{
            margin-bottom: 0;
          }
        }

      }

      #why-section-7 {
        img {
          margin-bottom: -15px;

        }
      }

      .why-section-7-mobile {
        .cadre-homepage {
          padding: 50px 20px 20px 20px;
        }

        .cadre-homepage-2 {
          padding: 50px 20px 20px 20px;
        }
      }

      .qui-somme-nous-title {
        p {
          margin-bottom: 0px;
        }
      }

      #why-section-7 {
        padding-bottom: 10px;

        .img-why-section {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: -30px;
          margin-top: -64px;
        }

        video-why-section {
          height: 250px;
          & > div {
            height: 262px;
            width: 380px;
            margin-top:40px;
          }
        }

        .img-why-section-2 {
          margin: 0 0 -30px 0;
        }

        img {
          width: 100%;
        }
      }

      #why-section-7 > div {
        justify-content: center;
      }

      .analyse-block {
        border-right: none!important;
      }

      .analyse-block-2 {
        margin-top: 0!important;
      }

      .mobile-tiret {
        display: flex;
        align-items: center;
        margin-left: -25px;

        .tiret {
          width: 20px;
          height: 1px;
          border: 1px solid $txBlue;
          background-color: $txBlue;
          display: inline-block;
          margin-right: 5px;
        }
      }

      .article-press {
        width: 204px;
        height: 200px;
        padding: 0 10px;
        margin: auto;

        h2 {
          width: 180px;
          text-align: center;
          img {
            display: inline-block!important;
            width: 70%;
          }
        }

        p {
          color: $txBlue;
          font-size: 14px;
          line-height: 16px;
          margin-bottom:10px;
        }
      }


    }


    @include media-breakpoint-down(sm) {

      .block-kit {
        .sub-block-kit {
          font-size: 19px;
          height: 176px;
        }

        .text-grey {
          color: darkgrey;
          font-size: 13px;
        }

        img {
          width: 150px;
        }

        button {
          width: 180px;
          font-size: 13px;
          height: 30px;
        }
      }

      #home-banner {
        padding-top: 72px;
        height: calc(100vh - 40px);
        background: #e7e6e2 url('/images/home/analyse-sante-toxseek-mobile.jpg') no-repeat 100% 100%;
        align-items: flex-start;
        background-size: 129%;

        h1 {
          text-align: center;
          text-align-last: center;
          max-width: 400px;
          margin: 0 auto 30px auto;
        }

        p {
          text-align: center;
        }

        .logo-mobile {
          text-align: center;
          display: block;
          margin: 0 auto 20px auto;
        }

        .banner-text {
          max-width: 600px;
        }

        p {
          max-width: 600px;
        }
      }

      .block-qui-somme-nous {

        .second-item {
          padding-left: 50px;
        }

        .grey-block{
          padding-left:50px;
        }

        .image-structure-orange {
          display: none;
        }
      }

      .homepage-text-footer {
        font-size: 1.3em;
        margin: 12% 0 0 0;
      }


      #why-section-6 {
        margin-bottom: 80px;
      }

      #why-section-7{
        //margin-top:80px;

      }
      #why-section-7 {
        .video-why-section {
          & > div {
            width: 405px;
          }
        }
      }

    }

    @include media-breakpoint-down(xs) {

      .hide-for-mobile {
        display: none!important;
      }

      .for-mobile {
        display: block;
      }

      .slick-initialized .slick-slide {
        border-right: none;
      }

      #home-banner {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;
        padding: 50px 0 20px 0;
        //background: #e5e4e0 url(/images/home/analyse-sante-toxseek-carre.jpg) no-repeat 99% 277%;
        //background-size: 155%;
        //background: #e7e6e2 url(/images/home/analyse-sante-toxseek-carre.jpg) no-repeat bottom 99% 100%;
        background-size: 161%;


        .for-mobile {
          width: 100%;
        }

        .for-mobile p {
          //width: 248px;
          text-align: center;
          margin: auto;
        }

        h1 {
          font-size: 1.6em!important;
          padding: 0 10px;
        }

        .banner-text {
          font-size: 1.2em !important;
          line-height: 1.2em;
          padding: 0 20px;
        }

        .for-mobile p {
          font-size: 1.4em!important;
          line-height: 1.2em;
        }


        .logo-mobile {
          width: 200px;

          img {
            width: 100%;
          }
        }

        h1 {
          font-size: 1.4em;
          text-align: center;
          text-align-last: center;
        }

        p.color-blue {
          height: 100%;
          //display: flex;
          align-items: flex-end;
          margin-bottom: 0;
          justify-content: center;
        }
      }

      #why-section-6{
        .analyse-block {
          p:nth-child(5){
            margin-bottom:0px;
          }
        }
        .analyse-block-2 {
          img {
            max-width: 100%;
          }
        }
      }


      .block-kit {
        height: 180px;

        .sub-block-kit {
          height: 112px;
          margin-top: 10px;
        }

        button{
          margin-top:17px;
        }
      }

      .img-why-section-2 {
        padding: 0;
      }

      .no-padding-mobile {
        padding: 0;
        overflow: hidden;
      }

      .homepage-text-footer {
        margin: 18% 0 0 0;
      }

      .cadre-homepage {
        padding: 40px 25px 60px 25px;


      }

      #press{
        padding: 30px;

        p{
          margin-top:0;
        }
      }


      .container-kit {
        margin-bottom: 30px;
      }


      #why-section-7 {
        max-width: 100%;
        .video-why-section {
          & > div {
            height: 200px;
            width: 139%;
            margin-left: -20%;
          }
        }
      }
    }




    .footer-address {
      padding: 1% 1%;
      font: 450 12px/15px Muli;
    }

    .footer-line {
      width: 100%;
      border-bottom: 1px solid $txGreyOne;
      margin: 1% 0;
    }



