@include media-breakpoint-up(lg) {

	#site-menu a {
		@include font-size(.9375rem);
	}

	#site-footer-logo {
		/* height: 52px; */
		margin: 26px 0 15px;
		border-top: 1px solid #f13c11;
	}

	#site-footer-logo > div {
		position: relative;
		top: -26px;
		margin: 0 -15px;
	}

	#site-footer-menus {
		border-right: 1px solid #f13c11;
		border-top: none;
		border-bottom: none;
	}

	#site-footer-mail {
		@include font-size(0.75rem);
	}

	#site-footer-legal {
		@include font-size(.75rem);
	}

	/***
	Order
	***/
	.order-form {
		@include font-size(.9375rem);

		.order-panel {

			h3 {
				@include font-size(1rem);
			}

			.product-item {

				&-logo {
					width: 184px;
					height: 75px;
				}

				&-subtitle {
					@include font-size(.875rem);
					line-height: 125%;
				}
			}
		}
	}

	/***
	Why
	***/
	#why-banner {
		height: 354px;

		h1 {
			font-size: pxrem(26);
		}
	}

	#why-section-3 {

		> .container {
			position: relative;
			margin-bottom: 160px;

			.vulnerable-illustration {
				position: absolute;
				top: 50%;
				width: 366px;
				margin-top: -202px;
				text-align: left;

				img {
					width: 366px;
				}

				.note {
					text-align: left;
				}
			}

			h2,
			.vulnerable-text-1 {
				padding-left: 400px;
			}
		}
	}

	#why-section-4 {

		.chapeau {
			padding: 0 60px;
		}
	}

	/***
	Analyses
	***/
	#page-title {

		&.analyses-page-title {

			h1 {
				font-size: pxrem(22);
				line-height: pxrem(23);
				letter-spacing: pxrem(.15);
			}
		}
	}

	.analyses-page-banner {

		> .container {
			height: 354px;
			background-position: 65% 100%;
			background-size: 1876px;
		}

		&-content {
			position: absolute;
			bottom: 40px;
			width: 50%;

			img {
        width: 270px;
      }
		}
	}

	.analyses-insert-2 {
		margin-top: 100px;
	}

	.functioning {

		h2 {
			margin-bottom: 45px;
			@include h3-style;
		}

		.functioning-step {
			display: flex;
			align-items: center;
			height: 140px;
			margin: 0 5px;
			padding: 20px 30px 20px 50px;
			text-align: left;

			&::before {
				top: calc(50% - 30px);
				left: -30px;
			}

			&::after {
				// position: absolute;
				left: 92%;
				top: 50%;
				z-index: 1000;
				width: 40px;
				height: 0;
			}
		}
	}

	.analyses-accordion {

		.accordion-wrapper {
			position: relative;

			.accordion-items {
				width: 50%;
				padding-right: 20px;
				border-right: 1px solid $txOrange;

			}

			.accordion-item {

				&-header {
					margin-bottom: 30px;
					font-size: pxrem(16);
				}

				&-content {
					position: absolute;
					right: 0;
					top: 30%;
					z-index: 1000;
					// display: table-cell;
					width: 50%;
					// height: 100%;
					padding-left: 20px;
					// vertical-align: middle;
					// background-color: #fff;
				}

				&::after {
					display: none;
				}
			}
		}
	}

}
