
.create-account-form {

  .error {
    width: 100%;
    color: $danger;
    text-align: center;
    margin-top: 10px;
  }
}
