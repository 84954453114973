
.payment-page {
  .payment-page-container {
    @extend .container;
    .section {
      width: 90%;
      margin: 5% auto;
    }
    .security {
      padding: 0 1%;
      white-space: nowrap;
      font: 450 15px/19px Muli;
      div {
        padding-right: 5%;
        display: inline-block;
      }
      img {
        width: 21px;
      }
    }
    .payment-page-recap {

      white-space: nowrap;
      table {
        width: 100%;
        text-align: right;
        .col-product {
          margin-left: auto;
          width: 64%;
        }

        .col-pu, .col-quantity {
          text-align: center;
        }
        .product-line {
          .description {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            text-align: left;
            white-space: normal;
            img {
              //width: 50%;
              padding: 2% 3% 2% 0;
              width :150px;
              @include media-breakpoint-down(xs) {
                width: 90%;
                align-self: flex-start;
              }
            }
            span {
              @include media-breakpoint-down(xs) {
                font: 300 10px/12px Muli;
              }
            }

            @include media-breakpoint-down(sm) {
              flex-flow: column;
            }
          }
        }
        thead {
          th {
            width: 12%;
            font: 600 13px/16px Muli;
          }
        }
        tfoot {
          .total {
            th, td {
              font: 700 15px/19px Muli;
            }
          }
          th, td {
            font: 300 15px/19px Muli;
            border-top: 1px solid #e7e7e7;
            text-align: left;
          }
          td {
            text-align: right;
            padding: 1% 0;
          }
        }
      }
    }
  }
}


.stripe-container {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-items: center;
  white-space: nowrap;
  width: 100%;


  .card-number-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    @include media-breakpoint-down(xs) {
      flex-flow: column-reverse nowrap;
    }
    .stripe-card-type {
      width: 200px;
      justify-self: flex-end;
      align-self: flex-start;

      @include media-breakpoint-down(xs) {
        justify-self: flex-start;
        align-self: flex-end;
        margin-right: auto;
      }
    }
  }

  .stripe-control-container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap;
      align-items: flex-start;

    }
    &.card-number {
      margin-top: 15px;
    }


    label {
      width: 150px;
    }

    .stripe-control {
      //height: 40px;
      border: 1px solid #b2b2b2;
      vertical-align: middle;
      border-radius: 0px;
      padding: 1%;
      &.card {
        width: 220px;
      }

      &.expiry {
        width: 100px;
      }

      &.cvc {
        width: 100px;
      }
      &.description {

      }
    }


  }
  span {
    font-size: 13px;
    margin-left: 150px;
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }
  }


}
