
.translation-page {
  .missing-translation {
    ul {
      overflow: scroll;
      width: 100%;
      .language-title {
        border-bottom: 1px solid $txBlue;
        font-size: 23px;
        width: 100%;
        color: $txBlue;
        text-align: center;
        padding: 10px;
        text-transform: uppercase;
      }
    }
    li {
      list-style: none;
      font-size: 12px;
      white-space: nowrap;
      .key {
        font-weight: 700;
        color: #1c7430;
      }
      .value {
        font-weight: 400;
      }
    }
  }
}
