

.information-ribbon-container {
  width: 100%;
  text-align: center;
  display: block;
  padding: 0.5em 1em;
  background-color: #bf2c2c;
  color: #fff;
  font-size: 0.8em;
}