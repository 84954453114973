



//$enable-responsive-font-sizes: true;
$txGreyOne: #707070;
$txGreyTwo: #f5f5f5;

$dest: global #707070;

$txBlue: #171796;
$txBlueButton: #16178b;
$txOrange: #f13c11;

$txHoverColor: $txOrange;



// $rem8px: .5rem;
// $rem10px: .625rem;
// $rem11px: .6875rem;
// $rem12px: .75rem;
// $rem13px: .8125rem;
// $rem14px: .875rem;
// $rem15px: .9375rem;
// $rem16px: 1rem;
// $rem17px: 1.0625rem;
// $rem18px: 1.125rem;
// $rem19px: 1.1875rem;
// $rem20px: 1.25rem;
// $rem21px: 1.3125rem;
// $rem22px: 1.375rem;
// $rem23px: 1.4375rem;
// $rem24px: 1.5rem;
// $rem25px: 1.5625rem;
//
// $rem28px: 1.75rem;
// $rem29px: 1.8125rem;
