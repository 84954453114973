@include media-breakpoint-up(xl) {

	#site-menu a {
		@include font-size(1rem);
	}

	#site-footer-mail {
		@include font-size(0.875rem);
	}

	/***
	Why
	***/
	#why-banner {
		height: 398px;

		h1 {
			font-size: pxrem(26);
		}
	}

	#why-section-3 {
		margin-bottom: 180px;
		background-color: transparent;

		> .container > .row {
			padding: 20px 0;
		}
	}

	#why-section-4 {

		.chapeau {
			padding: 0 150px;
		}
	}

	#why-section-5 {
		background-color: transparent;
	}

	#why-section-7 {
		.cadre {

			padding-top: 60px;
			padding-bottom: 60px;
		}
	}

	/***
	Analyses
	***/
	#page-title {

		&.analyses-page-title {

			h1 {
				font-size: pxrem(24);
				line-height: pxrem(27);
				letter-spacing: pxrem(.17);
			}
		}
	}



}
