
.chip {
  line-height: 60%;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  background: #171796 0% 0% no-repeat padding-box;
  color: white;
  font-size: 24px;
  padding: 8px;
}
