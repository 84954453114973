.hidden {
  visibility: hidden !important;
  height: 0;
}

.visible {
  visibility: visible !important;
}
.account-created-dialog {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  .body {
    width: 70%;
    color: #171796;
    margin-bottom: 6%;
  }
  .advice {
    width: 70%;
    color: #707070;
    margin-bottom: 6%;
  }

}
.activation-form{
  .col-6{
    padding-right:0;
    margin-right:0;
  }
  .field-product-code-help-label{
    padding-left:0;
    margin-left:0!important;
  }
}


.activation-page {
  .input-group {
    align-items: center;
    label {
      margin: 0 10px 0 0;
    }
  }

  header {
    background-color: #f2f2f2;
    padding: 40px;
    margin-bottom: 4%;

    h1 {
      margin-bottom: 0 !important;
      margin-top:0;
    }
  }

  .data-wrapper {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    span {
      margin-bottom: 5px;
    }
  }
  .field-control-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
      align-items: flex-start;
    }
    .control-wrapper {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  .checkbox-control-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .activation-form-section {
    margin-bottom: 50px;

    .button-login {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto;

      p {
        margin: 0 0 0 10px;
        color: $txBlue;
      }

      .button-have-account {
        margin: 0 10px 0 0;
        width: initial !important;
      }

    }
  }

  .field-product-code-help-body {
    p {
      font-size: 14px;
    }

    .row > div {
      margin: auto;
    }

    img {
      max-width: 200px;
    }
  }
  .button-line{
    margin-bottom: 3%;
  }

  .title-activation {
    text-align: center;
    margin-bottom: 6%;
    .title {
      font: 200 18px/25px Muli;
      color: #171796;
    }
    .subtitle {
      font: Light 14px/18px;
      color: #707070;
    }
  }

  .create-account-link-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .label {
      text-align: left;
      font: 400 14px/18px Muli;
      letter-spacing: 0;
      color: #171796;
    }
    .link {
      margin-left: 0.5rem;
      text-align: left;
      text-decoration: underline;
      font: Bold 14px/18px Muli;
      letter-spacing: 0;
      color: #171796;
    }
  }

  .identity-form-block-fullname {
    display: flex;

    .field-error-control {
      display: flex;
      align-items: center;

      label {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }

    .identity-civility {
      margin-right: 20px;
    }

    .identity-firstname {
      margin-right: 20px;
    }

    .identity-lastname {

    }

    .identity-form-block-firstname {
      display: flex;
    }
  }

  .identity-email {
    margin-right: 20px;
  }

  .identity-form-block-email-phone {
    display: flex;
    margin-top: 20px;

    .intl-tel-input {
      display: flex;
      flex: 1 1;

      input {
        width: 100%;
      }
    }

  }

  .activation-form-section-validation {
    margin: 0 0 0 0;
  }

  .field-product-code-activation {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    .input-group {
      height: 100%;
    }

    .field-error-control {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .field-product-code-help-label {
      text-align: center;
    }

    input.field-product-code {
      text-align: center;
      font-size: 20px;
    }
  }

  .product-code {
    .field-error-message {
      text-align: center;
    }
  }

  .block-date-consent-activation {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    margin-top: 20px;

    .consent-block-input {
      display: flex;
      align-items: flex-start;

      &.minor-consent {
        label {
          //color: $txOrange;
          color:#ff0000;
        }
      }

      label {
        margin-bottom: 0;
      }

      input {
        margin-right: 20px;
      }
    }

    /*& > div {
      margin: 10px 0;
    }*/


    label {
      font-size: 0.9em;
    }

    .field-date-activation {

      .field-error-container {
        display: flex;
        align-items: flex-start;
        flex-flow: column;
      }

      .field-date {
        display: flex;
        align-items: center;

        .form-label {
          white-space: nowrap;
          margin-right: 20px;
        }

      }

      .react-datepicker-wrapper {
        width: 100%;
      }

      input {
        width: 100%;
      }
    }
  }

  .activation-consents {
    display: flex;
    margin: 50px auto;

    .field-error-container {
      width: 100%;
    }

    .field-error-control {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      .div1 {
        text-align: right;

        .form-check {
          padding-left: 0;
        }

        label {
          text-align: center;
          width: 100%;
        }
      }

      .div2 {
        text-align: center;
      }

      .div3 {
        text-align: center;

        .consent-minor {
          padding-left: 0;
        }

      }
    }
  }

  .consent-minor {
    input {
      visibility: hidden;
    }

    label {
      max-width: 400px;
      width: auto;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
      background-color: #656565;
      border-color: #656565;
      border-radius: 0;
      padding: 10px;
    }
  }

  .field-consent-checkbox label {
    max-width: 400px;
    width: auto;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    background-color: #656565;
    border-color: #656565;
    border-radius: 0;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
  }

  .field-consent-checkbox-checked {
    label {
      background-color: #171796 !important;
      border-color: #171796 !important;
    }
  }

  .consent-adult {
    input {
      visibility: hidden;
    }


  }
}

@include media-breakpoint-down(md) {


  .field-product-code-activation {
    .field-error-control {
      .block-input-product-code {
        padding: 0;
      }
    }
  }

  .field-date-activation {

    .field-error-container {

      .field-error-control {
        width: 100%;

        .field-date {
          flex-direction: column;
          align-items: flex-start !important;
        }
      }
    }
  }


  .activation-form-section {
    .button-login {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 30px auto !important;

      p {
        margin: 10px 0 0 0 !important;
        color: $txBlue;
      }

      .button-have-account {
        margin: 0 0 10px 0 !important;
        width: initial !important;
      }

    }
  }


  .activation-page {
    .identity-form-block-fullname {
      display: block;

      .field-error-control {
        display: block;
      }

      .identity-form-block-firstname {
        display: block;
      }
    }

    .identity-form-block-email-phone {
      display: block;
    }

  }
}

@include media-breakpoint-down(sm) {
  .field-product-code-help-body {
    .row > div {
      margin: auto;
    }

    img {
      //max-width: 200px;
      width:150%!important;
    }
  }
  .activation-consents {
    display: flex;
    margin: 50px auto;

    .field-error-container {
      width: 100%;
    }


    .field-error-control {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      .div1 {
        text-align: center !important;

        .form-check {
          padding-left: 0;
        }

        label {
          text-align: center;
          width: 100%;
        }
      }

      .div2 {
        text-align: center;
        margin: 20px auto;
      }

      .div3 {
        text-align: center;

        .consent-minor {
          padding-left: 0;
        }

      }

    }
  }
}

@include media-breakpoint-down(xs) {

}
