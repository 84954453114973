.order-form {
  padding-top: 50px;
  @include font-size(.75rem);
  font-weight: 300;
  color: #707070;

  input {
    border-radius: 0;
    border-color: $txGreyOne;
  }

  .input-group {
    display: flex;
    flex-flow: column nowrap;
  }
  .order-panel {
    margin-bottom: 50px;
    border-top: 1px solid $txBlue;
    border-radius: 0;

    .resume-line {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }
    .order-panel-wrapper {

      position: relative;
      top: -.7rem;

    }
    &.order-panel-identity {
      .btn {
        min-width: 50%;
        margin-bottom: 5%;
        @include media-breakpoint-down(sm) {
          min-width: 70%;
        }
      }
    }
    &.order-panel-prescriber {
      .order-panel-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        justify-content: center;

      }


      .order-panel-prescriber-body {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font: 300 15px/19px Muli;

        span {
          color: $txBlue;
        }
        @include media-breakpoint-down(md) {
          flex-flow: column;
          .info {
            margin-bottom: 3%;
          }
        }
      }
    }
    h3 {
      display: inline-block;
      margin-bottom: 30px;
      padding-right: 15px;
      @include font-size(.8125rem);
      text-transform: uppercase;
      font-weight: 600;
      color: $txBlue;
      background-color: #fff;
    }

    &-resume {
      border: none;

      h3 {
        display: none;
      }
    }


    .product-item {
      min-height: 6rem;
      margin-bottom: 20px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      @include media-breakpoint-down(sm) {
        flex-flow: column nowrap;
      }
      .order-logodesc {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        width: 70%;
        .unit-price {
          font-weight: 600;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }
        @include media-breakpoint-down(xs) {
          flex-flow: column nowrap;
          width: 100%;

        }
        img {
          @include media-breakpoint-down(xs) {
            justify-self: flex-start;
            margin-right: auto;
          }
        }
      }

      &-logo {
        width: 33%;
        // height: 75px;
        //width: 84px;
        height: 33px;
        // margin-right: 15px;
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        flex: none;
      }

      &-subtitle {
        padding: 0 15px;
        flex: auto;
        @include font-size(.8rem);
        line-height: 125%;
        @include media-breakpoint-down(xs) {
          padding: 0;
          align-self: flex-start;
        }
      }

      &-selector {
        align-self: flex-end;
        margin-left: auto;
        width: 200px;
        @include media-breakpoint-down(sm) {
          justify-self: flex-end;
          margin-left: auto;
          width: 160px;
        }
        @include media-breakpoint-down(xs) {
          width: 120px;
        }
      }

      &-logo span {
        visibility: hidden;
      }

      &-prescriber {
        margin-bottom: 40px;

        .input-group {
          display: inline-block;
        }

        .btn {
          border-radius: 0;
          background-color: #999;
          color: #fff;
        }
      }

      &-selector-item {

        display: flex;
        flex-flow: row nowrap;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance:textfield;
        }
        //width: 100%;
        input {
          //width: 40%;
          text-align: center;
          border: none;
          margin: 0 1%;

        }
      }

    }
    &-validation {
      .order-form-validation-container {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .accept {
          text-align: left;
          width: 100%;
        }

        .create-account-info-wrapper {
          width: 90%;
          border: 1px solid $txBlue;
          padding: 10px 19px;
          text-align: center;
          margin-bottom: 2%;
          .title {

            text-transform: uppercase;
            color: $txOrange;
            font: 700 15px/19px Muli;
          }
          .body {
            font: 300 15px/19px Muli;
          }
          .footer {
            font: 300 13px/17px Muli;
          }
        }
      }
      /*.btn {
        width: 100%;
      }*/
    }

  }

  &-prescriber-container {
    //position: relative;
    //left: 11rem;
    //top: -3.6rem;
    display: flex;
    //width: 22.5rem;
    padding: 0 30px 0 0;
    background-color: #fff;
    .input-group {
        flex-flow: row nowrap;
    }
    .ts-button-prescriber-validation {
      margin-left:30px;
      height: calc(1.5em + 0.75rem + 2px);
    }
  }
}
