/***
Header
***/
#site-header a:hover,
#site-header a.active,
#site-header button:hover {
  // font-weight: 800;
  text-decoration: none;
  color: $txHoverColor;
}


.default-logo img {
  max-width: 200px;
  //height: 100%;
}


#menubar {
  position: relative;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  // background-color: #f5f5f5;
}

.logo-mobile {
  z-index: 500;
}

.site-logo {
  display: none;
}

.mobile-logo {
  padding: 15px;
}

/***
Site nav
***/

#site-menu {
  //display: flex;
  //flex-flow: row nowrap;
  svg {

    position: absolute;
    //align-self: center;
    //justify-self: self-end;
    margin: auto;
    margin-right: 10px;
    right: 10px;
    top: 10px;
    z-index: 10;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  a {
    @include font-size(.6875rem);
    text-transform: uppercase;
    color: $txBlue;
  }

  button {
    position: relative;
    border: none;
    background-color: transparent;
    margin: 0 !important;
  }
}


.button-burger {
  height: 100%;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 0 10px;
}

.button-burger > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon-bar {
  background-color: #c4c4c4;
  display: block;
  width: 35px;
  height: 4px;
  border-radius: 1px;
}

.icon-bar + .icon-bar {
  margin-top: 6px;
}

#menu4 {
  display: none;
}

.liste-menus.opened {
  width: 100%;

  ul {
    li {
      a {
        font-size: 1.5em !important;
        line-height: 1.8em;
      }
    }
  }

  .account-menu button {
    font-size: 1.5em !important;
    line-height: 1.8em;
    text-align: left;
    color: $txBlue;
    margin: 0 !important;
    text-transform: uppercase;
    padding: 0;
  }

  #menu1 {
    border-bottom: 1px solid $txOrange;
    max-width: 270px;
    margin: auto;
    text-align: left;
    padding: 20px 0;
  }

  #menu2 {
    border-bottom: 1px solid $txOrange;
    max-width: 270px;
    margin: auto;
    text-align: left;
    padding: 20px 0;

    a:first-child {
      color: $txOrange;
    }
  }


  #menu3 {
    margin-left: 20px;
    line-height: 1.8em;
  }

  #menu4 {
    max-width: 270px;
    margin: auto;
    text-align: left;
    padding: 20px 0;
    display: block;
  }
}

@include media-breakpoint-down(md) {
  #menubar {
    .container {
      width: 100%;
      max-width: 100%;
    }
  }
  .default-logo img {
    max-width: 170px;
    //height: 100%;
  }
}


@include media-breakpoint-down(sm) {
  .button-burger {
    display: flex;
  }
}


