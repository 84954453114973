input,
input.form-control,
.form-control input,
input#email,
select,
select.form-control {
  // width: auto;
  border: 1px solid #707070;
  border-radius: 0;
}

.btn {
  width: auto;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background-color: $txBlue;
  border-color: $txBlue;
  border-radius: 0;
}

.btn:hover {
  color: #fff;
}

.btn-primary:disabled {
  background-color: #999;
  border-color: #999;
}

/***
Validation
***/
.order-form-toxseek-urgence {
  font-weight: 400;
  color: $txBlue;
}

.form-check-label a {
  color: $txBlue;

}