$veterinaryDark: #5e5e5c;
$veterinaryLight: #2b9b84;

.veterinary {
  #site-header a:hover,
  #site-header a.active,
  #site-header button:hover {
    color: $veterinaryLight !important;
  }
}
.veterinary {
  #site-header a,
  #site-header button {
    color: $veterinaryDark !important;
  }
}
