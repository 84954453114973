
.account-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  height: 100%;

  .icon-disconnect {
    position: relative!important;
    top: 0!important;
    right:0!important;
    margin: 0!important;
    font-size: 20px;
    cursor: pointer;
  }

  a {
    color: unset;
  }

  button {
    background-color: transparent;
    border: none;
  }

  div {
    margin-right: 20px;
  }

  svg {
    display: block;
  }
}


.account-page {

  .account-tabs {
    //@extend .container;
    .tabs-content {

    }
  }

  h1 {
    color: #171796;
    padding: 2% 0;
    background-color: #F5F5F5;
    text-transform: none;
    margin-bottom: 0;
    margin-top: 0;
  }

  .account-tabs {

    .tabs-content {
      @extend .container;
      margin-top: 3%;
    }

    .tabs-header-item {
      white-space: nowrap;

      &.active {
        span {
          border-bottom: 1px solid $txOrange;
        }
      }
    }

    .profile-section {

      .button-line {
        @include media-breakpoint-down(xs) {
          flex-flow: row !important;
          align-items: center;

        }
      }
    }

    .profile-section-body-item {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;


      @include media-breakpoint-down(xs) {
        flex-flow: row !important;
        align-items: center;
      }

      .field-error-container {
        width: 40%;


        @include media-breakpoint-down(md) {
          width: 70%;
        }
        @include media-breakpoint-down(sm) {
          width: 100%;
        }

        .field-error-control {

          white-space: nowrap;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: flex-start;


          input:disabled, select:disabled {
            background-color: white;
            border: none;
          }

          .intl-tel-input {
            input {
              height: 100% !important;
            }
          }

          select:disabled {
            /* for Firefox */
            -moz-appearance: none;
            /* for Chrome */
            -webkit-appearance: none;

            /* For IE10 */
            select::-ms-expand {
              display: none;
            }
          }


          label {
            width: 75%;
          }

        }
      }

      div {
        padding-right: 1%;
      }
    }

    .spinner-wrapper {
      width: 100%;
      text-align: center;
    }

    .order-item {
      margin-bottom: 5%;
      border: 1px solid #8b8a8a;
      padding-bottom: 10px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 5%;
      }

      .order-item-header {
        padding: 2%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: #f4f3f3;

        .order-item-header-data {
          width: 25%;
          color: #171796;
          letter-spacing: 0;

          .label {
            font: 600 15px/19px Muli;
            @include media-breakpoint-down(xs) {
              font: 600 12px/14px Muli;
            }
          }

          .value {
            font: 300 15px/19px Muli;
            @include media-breakpoint-down(xs) {
              font: 300 12px/14px Muli;
            }

            &.state {
              color: $txOrange;
              .tracking {
                color: #171796;
              }
              &.paid, &.sent {
                color: #1c7430;
              }

              &.placed {
                color: $txBlue;
              }
              &.transfert-pending {
                color: $warning;
              }
            }

          }

          &.date {
            justify-self: flex-end;
            text-align: right;
            margin-left: auto;
          }

        }
      }

      .order-item-body {

        padding: .5%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 4%;
        @include media-breakpoint-down(xs) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;
        }

        .product-wrapper {
          display: flex;
          width: 100%;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;

          .product-items {
            width: 80%;
            display: flex;
            flex-flow: row;
            align-items: center;
            justify-content: space-between;

            padding: 0 2%;

            @include media-breakpoint-down(sm) {
              width: 100%;
            }

            @include media-breakpoint-down(xs) {
              width: 100%;
              justify-content: space-evenly;
              //flex-flow: column nowrap;
              //justify-content: flex-start;
              //align-items: center;
            }

            .product-item {

              padding: 1%;

              &.description {
                width: 60%;
                @include media-breakpoint-down(xs) {
                  display: none;
                  width: unset;
                  text-align: center;
                }

              }

              &.quantity {
                white-space: nowrap;
                font-size: 20px;
              }
            }

            img {
              width: 20%;
              @include media-breakpoint-down(sm) {
                width: 30%;
              }
            }

          }


        }
        .button-container {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          @include media-breakpoint-down(xs) {
            width: 100%;
          }

          button {
            justify-self: flex-end;
            background-color: white;
            border: 1px solid #8c8c8c;
            border-radius: 20px;
            white-space: nowrap;
            width: 100%;
            padding: 7px;
            margin-bottom: 5px;
            @include media-breakpoint-down(xs) {
              justify-self: unset;
              margin: 5%;
              width: auto;
              padding: 4%;
              background: #e8e8e8;
              font-size: 20px;
            }

            &:hover {
              background-color: #8c8c8c;
              color: white;
            }
          }
        }
      }

      .order-item-details {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-around;
        padding: 0 10px;
        @include media-breakpoint-down(sm) {
          justify-content: space-evenly;
          flex-flow: row wrap;
        }
        .order-item-detail-item {
          @include media-breakpoint-down(sm) {
            width: 45%;
            padding: 10px 0;
          }
          .order-item-detail-title {
            font-size: 0.8rem;
            font-weight: bold;
          }
          .order-item-detail-body {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-start;
            .order-item-detail-summary {
              display: flex;
              flex-flow: column nowrap;
              width: 100%;
              .summary-line {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                .summary-label {
                  padding-right: 15px;
                  white-space: nowrap;
                }
              }
            }
          }

        }
      }
    }

    .result-item {
      margin-bottom: 3%;
      border: 1px solid #f4f3f3;
      @include media-breakpoint-down(sm) {
        margin-bottom: 5%;
      }

      .result-item-header {
        padding: 2%;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        justify-content: space-between;
        background-color: #f4f3f3;
        width: 100%;



        .result-item-header-data {
          color: #171796;
          letter-spacing: 0;
          display: flex;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: flex-start;
          height: 100%;
          text-align: left;
          padding: 5px;
          &.date {
            justify-self: flex-end;
            //margin-left: auto;
            .value {
              @include media-breakpoint-down(xs) {
                font: 300 12px/16px Muli;
              }
            }
          }

          .label {

            font: 600 15px/19px Muli;
            //padding-bottom: 5px;
            @include media-breakpoint-down(xs) {
              font: 600 12px/14px Muli;
            }
          }

          .value {
            display: flex;
            flex-flow: column;
            font: 300 14px/16px Muli;
            width: 100%;
            text-align: left;
          }
        }
      }

      .result-item-body {

        padding: .5%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: flex-start;

        @include media-breakpoint-down(xs) {
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;
        }

        .product-wrapper {
          display: flex;
          width: 100%;
          flex-flow: column nowrap;
          align-items: flex-start;
          justify-content: center;

          .product-items {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .button-container {
              display: flex;
              justify-content: stretch;
              align-items: stretch;
              flex-grow: 1;
            }

            .product-item {
              padding: 5px;
              width: 25%;

              &.logo {
                max-width: 150px;
                padding-left: 15px;
              }

              &.description {
                flex-grow: 1;
                //text-align: center;
                padding-left: 5%;
              }
            }

          }

          .result-item-file {
            cursor: pointer;
            padding: 5px;
            display: flex;
            width: 100%;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;

            .result-item-file-name {
              padding: 10px;
              font-size: 20px;
            }

            &:hover {
              color: $txBlue;
            }
          }
        }

        button {
          padding: 1%;
          justify-self: flex-end;
          margin-left: auto;
          background-color: white;
          border: 1px solid #656565;
          @include media-breakpoint-down(xs) {
            justify-self: unset;
            margin: auto;
            padding: 4%;
            width: 100%;
          }
        }
      }
    }
  }


}
