
.ribbon-container {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
  z-index: 100;
  .ribbon {
    position: relative;
    margin: auto;
    width: 90%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    border: 1px solid #171796;
    button {
      width: 180px;
      height: 30px;
      background-color: #171796;
      color: white;
      font-size: 12px;
      font-weight: 600;
      font-family: inherit;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    background-color: white;
    .ribbon-text {
      padding: 10px;
    }
    .ribbon-close {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
