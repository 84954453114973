
.contact-page {
  h1 {
    color: #171796;
    padding: 2% 0;
    background-color: #F5F5F5;
    text-transform: none;
    margin-bottom: 0;
    margin-top:0;
    @include media-breakpoint-down(sm) {
      padding:2% 8%;

    }
  }
  .field-control-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap!important;
      align-items: flex-start;

    }

  }
  .form-footer {
    @extend .row;
    justify-content: flex-end;
    padding: 2% 11%
  }

  .form-header {
    padding: 4% 0;
    @include media-breakpoint-down(sm) {
      h3{
        font-size:1.05rem;
        line-height: 1.1rem;
      }

      .left{
        padding-right:0;
      }
    }

  }

  .field-error-control {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 80%;

    @include media-breakpoint-down(md) {
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    label {
      width: 20%;

      text-align: right;
      padding-right: 1%;

      @include media-breakpoint-down(sm) {
        width: unset;
        text-align: left;
      }

    }
  }

  .field-error-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .field-error-message {
    justify-self: center;
  }

  .group-control {

    display: flex;
    flex-flow: row !important;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    justify-self: center;
    margin: auto;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      flex-flow: column nowrap!important;
      align-items: flex-start;

    }

    .field-error-container:nth-child(1) {
      margin-left: 10.5%;
      @include media-breakpoint-down(md) {
        margin-left: 8.5%;
      }
      @include media-breakpoint-down(sm) {
        margin: auto;
      }
    }
    .field-error-control {
      /*@include media-breakpoint-down(md) {
        flex-flow: row;
        align-items: center;
      }*/
    }

    .field-error-control {
      width: 100%;

      label {
        width: fit-content;
        white-space: nowrap;
        padding: 0 2%;
      }
    }
  }

  .contact-tabs {
    .tabs-header {
      @extend .container;
    }

    .tabs-content {
      @extend .container;
    }

    .tabs-header {
      @include media-breakpoint-down(xs) {
        justify-content: center;
      }
    }
    .tabs-header-item {
      //width: 20%;
      margin: auto;
      &.active {
        span {
          border-bottom: 1px solid $txOrange;
        }
      }
      @include media-breakpoint-down(xs) {
        white-space: normal;
        font-size:11px;
        line-height: 14px;
      }
    }

    .form-header {
      display: flex;
      flex-flow: row nowrap;
      min-height: 100px;

      @include media-breakpoint-down(md) {
        flex-flow: column;
        align-items: center;
        justify-content: center;
      }

      h3 {
        //font: 300 24px/28px Muli;
        color: #171796;
        text-transform: none;
        letter-spacing: 0.17px;
      }

      .left {
        padding-right: 2%;
        text-align: right;
        align-self: flex-start;
        max-width: 60%;
        min-width: 60%;
        @include media-breakpoint-down(md) {
          max-width: 100%;
          min-width: 100%;
          text-align: center;
          br {
            display: none;
          }
        }
        @include media-breakpoint-down(sm) {
          padding-right:0;
          text-align: center;
          br {
            display: none;
          }
        }
      }

      .right {
        padding-left: 2%;
        text-align: left;
        align-self: flex-end;
        max-width: 40%;
        min-width: 40%;
        @include media-breakpoint-down(md) {
          max-width: 100%;
          min-width: 100%;
          text-align: center;
          br {
            display: none;
          }
        }
      }

      .separator {
        border-left: 1px solid #8a8bc5;
        margin: 1% 0 0 0;
        @include media-breakpoint-down(sm) {
          display: none;
        }
      }
    }
  }
}

