/*
Attribue un style h1
*/
@mixin h1-style {
  text-align: center;
  font-size: pxrem(18);
  line-height: pxrem(24);
  letter-spacing: .09rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $txOrange;

  @include media-breakpoint-up(sm) {
    font-size: pxrem(20);
    letter-spacing: .1rem;
  }

  @include media-breakpoint-up(md) {
    font-size: pxrem(23);
    line-height: pxrem(29);
    letter-spacing: .115rem;
  }
}

/*
Attribue un style h2
*/
@mixin h2-style {
  font-size: pxrem(14);
  line-height: pxrem(28);
  letter-spacing: .00625rem;
  font-weight: 300;
  text-transform: none;
  color: $txBlue;

  @include media-breakpoint-up(sm) {
    font-size: pxrem(16);
    letter-spacing: .006875rem;
  }


  @include media-breakpoint-up(md) {
    font-size: pxrem(20);
    letter-spacing: .00875rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: pxrem(24);
    letter-spacing: .010625rem;
  }
}

/*
Attribue un style h3
*/
@mixin h3-style {
  text-align: center;
  font-size: pxrem(20);
  line-height: pxrem(24);
  letter-spacing: .1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: $txOrange;

  @include media-breakpoint-up(md) {
    font-size: pxrem(23);
    line-height: pxrem(25);
    letter-spacing: .115rem;
  }
}

/*
Attribue un style h4
*/
@mixin h4-style {
  font-size: pxrem(15);
  line-height: pxrem(18);
  letter-spacing: 0;
  font-weight: 400;
  text-transform: none;
  color: $txBlue;

  @include media-breakpoint-up(sm) {
    font-size: pxrem(17);
  }
}

/*
Attribue un style p (texte courant)
*/
@mixin p-style {
  font-size: pxrem(13);
  line-height: pxrem(17);
  letter-spacing: 0;
  font-weight: 400;
  color: $txGreyOne;

  @include media-breakpoint-up(sm) {
    font-size: pxrem(15);
    line-height: pxrem(19);
  }
}


@mixin chapeau {
  text-align: center;
  font-size: pxrem(15);
  line-height: pxrem(17);
  letter-spacing: .00625rem;
  font-weight: 300;
  color: $txBlue;

  @include media-breakpoint-up(sm) {
    font-size: pxrem(16);
    line-height: pxrem(19);
    letter-spacing: .006875rem;
  }

  @include media-breakpoint-up(md) {
    font-size: pxrem(17);
    line-height: pxrem(21);
    letter-spacing: .0075rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: pxrem(18);
    line-height: pxrem(23);
    letter-spacing: .008125rem;
  }
}
