.section-headline{
    text-align: center;
    padding:0 8%;
}


h1 {
    margin-top: 50px;
}

.text-success{
    //margin-top: 50px;
    //color: #707070 !important;


}

.text-danger{
    //margin-top: 50px;
    //color: #707070 !important;
}


.text-warning{
    //margin-top: 50px;
    //color: #707070 !important;
}
